import React, { useEffect, useRef, useState } from 'react'
import { TouchableOpacity } from 'react-native'
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from 'react-native-responsive-screen'
import { Box, Flex, Image, useColorMode, Text } from 'native-base'
import { RFPercentage } from 'react-native-responsive-fontsize'
import Input from '../../../../components/Input'
import { useNavigation, useRoute } from '@react-navigation/native'
import Api from '../../../../Api/Api'
import Template from '../../../template/Index.tsx'
import styles from './styles'
import SkeletonItens from '../../../components/Skeleton/Itens'
import { FlatList } from 'react-native'
import { useTheme } from '../../../../ThemeProvider.tsx'
import { CommonActions } from '@react-navigation/native'

export default function Profissional({}) {
  const { getCodEmpresa, isMobile, configEmpresa } = useTheme()

  const navigation = useNavigation()
  const route = useRoute()

  const [profissionais, setProfissionais] = useState<any[]>([])
  const [profissionaisOriginal, setProfissionaisOriginal] = useState<any[]>([])
  const [filtro, setFiltro] = useState<String>('')
  const [buscando, setBuscando] = useState<Boolean>(true)

  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa)
  )

  const refModal = useRef<any>(null)

  const { colorMode, toggleColorMode } = useColorMode()

  useEffect(() => {
    getProfissionais(Number(getCodEmpresa))
  }, [])

  async function getProfissionais(id_empresa: number) {
    let profissionais = await Api.profissionais(id_empresa)

    if (
      route.params.pacoteSelecionado &&
      route.params.pacoteSelecionado.length > 0
    ) {
      const profissionalExtraido = profissionais.data.filter((item) => {
        return item.id === route.params?.pacoteSelecionado[0].funcionario.id
      })

      // profissionais.data = profissionalExtraido;

      if (route.params.servicos_adicionais) {
        navigation.navigate('Servicos', {
          profissional_selecionado: profissionalExtraido[0],
          ...route.params
        })
      } else {
        navigation.navigate('Data', {
          profissional_selecionado: profissionalExtraido[0],
          servicos_selecionados: [],
          ...route.params
        })
      }

      return
    }

    //

    setProfissionais(profissionais.data)
    setProfissionaisOriginal(profissionais.data)
    setBuscando(false)
  }

  function renderFiltro() {
    return (
      <Input
        value={filtro}
        type={'text'}
        icon={'search'}
        onChangeText={(text) => {
          setFiltro(text)
          const profissionais_copy = [...profissionaisOriginal]

          const profissionais_filtrados = profissionais_copy.filter((item) => {
            return item.nome.toUpperCase().includes(text.toUpperCase())
          })

          setProfissionais(profissionais_filtrados)
        }}
        colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
        corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
        width={'100%'}
        placeholder='Profissional'
        helperText='Informe o nome do profissional'
      />
    )
  }

  function onPressItemSelecionado(item) {
    if (getConfigEmpresa.utilizaPwaSimples || getConfigEmpresa.versaoPWA == 2) {
      navigation.navigate('Data', {
        profissional_selecionado: item,
        ...route.params
      })
      return
    }

    navigation.navigate('Servicos', {
      profissional_selecionado: item,
      ...route.params
    })
  }

  function linhaProfissional(item: any, index: number) {
    return (
      <Flex key={index} style={styles.containerFlex}>
        {isMobile && (
          <Image
            source={
              item.foto == null
                ? require('../../../img/avatar-pessoa.jpg')
                : {
                    uri: item.foto?.url
                  }
            }
            alt='Avatar'
            resizeMode='cover'
            height={95}
            width={'25%'}
            borderRadius={5}
          />
        )}

        <Text
          style={{
            fontSize: RFPercentage(2.5),
            marginLeft: 10,
            width: '75%',
            color: getConfigEmpresa.coresPWA.corFontePrimaria
          }}
        >
          {item.nome}
        </Text>
      </Flex>
    )
  }

  const renderItem = ({ item, index }) => (
    <TouchableOpacity
      key={index}
      style={[
        styles.pressableItem,
        { borderColor: getConfigEmpresa.coresPWA.corBordaItens }
      ]}
      onPress={() => onPressItemSelecionado(item)}
    >
      {buscando ? <SkeletonItens /> : linhaProfissional(item, index)}
    </TouchableOpacity>
  )

  const renderEmptyComponent = () => (
    <Text
      style={{
        textAlign: 'center',
        marginTop: 20,
        fontSize: RFPercentage(2.5)
      }}
    >
      Nenhum registro encontrado.
    </Text>
  )

  return (
    <Template
      titulo={'Selecione o Profissional'}
      messageAlerta='Por gentileza, selecione o profissional para realizar o serviço'
      onBotaoProximoClick={() => {
        refModal.current.abrirModal('Por favor, selecione um profissional')
      }}
      filtro={renderFiltro()}
      onBotaoVoltarClick={() => {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: 'TabNavigator', params: { screen: 'Inicio' } }]
          })
        )
      }}
      ref={refModal}
    >
      <Box style={styles.mainContainer}>
        <FlatList
          data={profissionais}
          renderItem={renderItem}
          ListEmptyComponent={
            buscando ? <SkeletonItens /> : renderEmptyComponent
          }
          keyExtractor={(item, index) => index.toString()}
          contentContainerStyle={{
            width: wp('98%')
          }}
        />
      </Box>
    </Template>
  )
}
