import { Box, Flex, Image, Text } from 'native-base'
import React, { useState, useRef, useEffect } from 'react'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'

import createStyles from './styles'

import { useTheme } from '../../../../ThemeProvider'

import { RFPercentage } from 'react-native-responsive-fontsize'
import Api from '../../../../Api/Api'
import { useNavigation, useRoute } from '@react-navigation/native'
import Template from '../../../template/Index'
import Input from '../../../../components/Input'
import { FlatList } from 'react-native'
import { TouchableOpacity } from 'react-native'
import SkeletonItens from '../../../components/Skeleton/Itens'

const Pacotes: React.FC = () => {
  const { cor_rgba, getCodEmpresa, configEmpresa } = useTheme()

  const navigation = useNavigation()
  const refModal = useRef()
  const route = useRoute()

  const [pacotes, setPacotes] = useState<any[]>([])
  const [pacotes_original, setPacotesOriginal] = useState<any[]>([])
  const [filtro, setFiltro] = useState<String>('')
  const [buscando, setBuscando] = useState<boolean>(false)

  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa)
  )

  const styles = createStyles(getConfigEmpresa.coresPWA)

  useEffect(() => {
    getPacotesCliente(Number(getCodEmpresa))
  }, [])

  async function getPacotesCliente(id_empresa: number) {
    setBuscando(true)

    const servicos = await Api.pacotesCliente(
      id_empresa,
      route.params.idCliente
    )

    if (!servicos.status) {
      refModal.current.abrirModal('Erro ao buscar Pacotes')
      setBuscando(false)
      return
    }

    setPacotes(servicos.data)
    setPacotesOriginal(servicos.data)
    setBuscando(false)

    // navigation.navigate("PacoteItens", { pacoteSelecionado: servicos.data[0] });

    // setBuscando(false );
  }

  function renderFiltro() {
    return (
      <Input
        type={'text'}
        icon={'search'}
        value={filtro}
        corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
        colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
        width={'100%'}
        onChangeText={(text) => {
          setFiltro(text)
          const servicos_copy = [...pacotes_original]
          const servicos_filtrados = servicos_copy.filter((item) => {
            return item.pacote.descricao
              .toUpperCase()
              .includes(text.toUpperCase())
          })
          setPacotes(servicos_filtrados)
        }}
        placeholder='Descrição'
        helperText='Informe a descrição do pacote'
      />
    )
  }

  function reotornaValidade(data: string) {
    const data_formatada = new Date(`${data}T00:00:00`)
    const dia = data_formatada.getDate()

    const mes = data_formatada.getMonth() + 1
    const ano = data_formatada.getFullYear()

    const mes_formatado = mes < 10 ? `0${mes}` : mes

    return `${dia}/${mes_formatado}/${ano}`
  }

  function retornaLinhaDiaSemana(dia: string, item: any, numeroDia: number) {
    return (
      <Text
        style={{
          backgroundColor: item.diasValidade.includes(numeroDia)
            ? 'green'
            : 'red',
          color: '#fff',
          padding: 3,
          borderRadius: 5,
          // width: 20,
          textAlign: 'center'
        }}
      >
        {dia}
      </Text>
    )
  }

  function linhaPacote(item: any) {
    const data_exibir = reotornaValidade(item.dataValidade)
    const valido = new Date(`${item.dataValidade}T23:59:00`)
    const data_maior = valido > new Date()

    const diasValidade =
      item.diasValidade != '' ? item.diasValidade.split(',') : []

    return (
      <Flex style={styles.containerFlex}>
        <Box style={styles.leftFlex}>
          <Image
            source={{
              uri: 'https://i.pinimg.com/originals/bf/5d/b5/bf5db5425b7823594844b3a110c58e0b.png'
            }}
            alt='Item'
            resizeMode='cover'
            style={styles.imageItem}
          />

          <Box>
            <Text
              style={[
                styles.itemTextWithMargin,
                { color: getConfigEmpresa.coresPWA.corFontePrimaria }
              ]}
              fontSize={'lg'}
            >
              {item.pacote.descricao}
            </Text>
            <Text
              style={[
                styles.itemTextWithMargin,
                {
                  color: data_maior
                    ? getConfigEmpresa.coresPWA.corTextoDisponivel
                    : getConfigEmpresa.coresPWA.corTextoIndisponivel
                }
              ]}
              fontSize={'sm'}
            >
              {'Válido até: ' + data_exibir}
            </Text>

            <Text
              style={[
                styles.itemTextWithMargin,
                {
                  color: getConfigEmpresa.coresPWA.corFontePrimaria
                }
              ]}
              fontSize={'sm'}
            >
              Disponiveis P/ Utilização
            </Text>

            <Box
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: 10,
                marginTop: 4
              }}
            >
              {retornaLinhaDiaSemana('S', item, 1)}
              {retornaLinhaDiaSemana('T', item, 2)}
              {retornaLinhaDiaSemana('Q', item, 3)}
              {retornaLinhaDiaSemana('Q', item, 4)}
              {retornaLinhaDiaSemana('S', item, 5)}
              {retornaLinhaDiaSemana('S', item, 6)}
              {retornaLinhaDiaSemana('D', item, 0)}
            </Box>
          </Box>
        </Box>

        <Flex style={styles.rightFlex}>
          <Text style={styles.itemText}>{`R$ ${item.valor}`}</Text>
        </Flex>

        {!data_maior && <Box style={styles.overlayBox}></Box>}
      </Flex>
    )
  }

  function onPressPacoteSelecionado(item: any) {
    const data_exibir = reotornaValidade(item.dataValidade)
    const valido = new Date(`${item.dataValidade}T23:59:00`)
    const data_maior = valido > new Date()

    if (!data_maior) {
      return
    }

    navigation.navigate('PacoteItens', { pacoteSelecionado: item })
  }

  const renderItem = ({ item, index }) => (
    <TouchableOpacity
      key={index}
      style={[
        styles.pressableItem,
        {
          borderColor: item.selecionado
            ? cor_rgba ?? 'defaultColor'
            : getConfigEmpresa.coresPWA.corBordaItens
        } //mais clara
      ]}
      onPress={() => onPressPacoteSelecionado(item)}
    >
      {buscando ? <SkeletonItens /> : linhaPacote(item)}
    </TouchableOpacity>
  )

  const renderEmptyComponent = () => (
    <Text
      style={{
        textAlign: 'center',
        marginTop: 20,
        fontSize: RFPercentage(2.5)
      }}
    >
      Nenhum registro encontrado.
    </Text>
  )

  return (
    <Template
      titulo={'Seus Pacotes'}
      messageAlerta='Nesta tela você pode visualizar os seus pacotes disponíveis'
      onBotaoProximoClick={() => {
        refModal.current.abrirModal('Por favor, selecione um pacote!')
      }}
      onBotaoVoltarClick={() => {
        navigation.goBack()
      }}
      filtro={renderFiltro()}
      ref={refModal}
    >
      <Box style={styles.mainContainer}>
        <FlatList
          data={pacotes}
          renderItem={renderItem}
          ListEmptyComponent={
            buscando ? <SkeletonItens /> : renderEmptyComponent
          }
          keyExtractor={(item, index) => index.toString()}
          contentContainerStyle={{
            width: wp('98%')
          }}
        />
      </Box>
    </Template>
  )
}

export default Pacotes
