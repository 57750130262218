import React, { useState, useRef, useEffect } from 'react'
import { Animated, ScrollView, TouchableOpacity, View } from 'react-native'
import { Box, Image, Text, useColorModeValue } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import styles from './styles'
import { FontAwesome } from '@expo/vector-icons'

import ModalConfirmacao from '../../../../components/ModalConfirmacao'
import imageNaoEncontrada from '../../../img/nao_encontrado.png'
import Footer from '../../../components/Footer/Index'
import packageJson from '../../../../package.json'
import BannerInicio from '../../../img/bannerInicio.png'
import SkeletonInicio from '../../../components/Skeleton/Inicio'
import HeaderInicio from '../../../components/HeaderIncio/Index'
import Alerta from '../../../components/Alerta/Index'
import { Card } from 'primereact/card'
import { TitleItem } from '../../../components/Title/Index'

interface Layout1Props {
  dados_empresa: any // Substitua 'any' pelo tipo correto
  empresaNaoEncontrada: boolean
  exibeBotaoAgendarPacote: boolean
  isMobile: boolean
  colorMode: any // ou o tipo específico se houver
  primaryColor: any // ou o tipo específico se houver
}

const Layout1: React.FC<Layout1Props> = ({
  dados_empresa,
  empresaNaoEncontrada,
  exibeBotaoAgendarPacote,
  isMobile,
  colorMode,
  primaryColor
}) => {
  const [textoModal, setTextoModal] = useState('')

  const navigation = useNavigation<StackNavigationProp<any>>()
  const refModal = useRef<any>(null)
  const images = [
    'https://i.ibb.co/Ms5yNC2/foto1.jpg',
    'https://i.ibb.co/wg7YtzR/foto3.jpg',
    'https://i.ibb.co/LkDWFRS/foto2.jpg'
  ]
  ;('')
  const itens_menu = [
    { id: 1, nome_menu: 'Início' },
    { id: 2, nome_menu: 'Serviços' },
    { id: 3, nome_menu: 'Produtos' },
    { id: 4, nome_menu: 'Contato' }
    // { id: 5, nome_menu: "sobre" },
  ]

  const backgroundColor = useColorModeValue('white', 'black')

  const shakeAnim = useRef(new Animated.Value(0)).current // Inicializa opacidade em 0

  useEffect(() => {
    Animated.sequence([
      Animated.timing(shakeAnim, {
        toValue: -10, // Move para a esquerda
        duration: 100,
        useNativeDriver: true
      }),
      Animated.timing(shakeAnim, {
        toValue: 10, // Move para a direita
        duration: 100,
        useNativeDriver: true
      }),
      Animated.timing(shakeAnim, {
        toValue: -10, // Move para a esquerda novamente
        duration: 100,
        useNativeDriver: true
      }),
      Animated.timing(shakeAnim, {
        toValue: 10, // Move para a direita novamente
        duration: 100,
        useNativeDriver: true
      }),
      Animated.timing(shakeAnim, {
        toValue: 0, // Volta ao centro
        duration: 100,
        useNativeDriver: true
      })
    ]).start()
  }, [])

  function boxMaps() {
    return (
      <Box style={styles.subContainer2}>
        <Box style={styles.locationTitleContainer} padding={5}>
          <TitleItem
            title='Localização'
            subtitle='Venha nos fazer uma visita'
          />
        </Box>

        <Box style={styles.iframeContainer} marginBottom={5}>
          <iframe
            src={dados_empresa.urlMaps}
            width='95%'
            height={isMobile ? '375' : '600'}
            style={styles.iframe}
            loading='lazy'
          ></iframe>
        </Box>
      </Box>
    )
  }

  function boxBotaoAgendarClienteFiel() {
    return (
      <TouchableOpacity
        style={[
          styles.button,
          {
            backgroundColor:
              colorMode == 'dark' ? '#000' : primaryColor ?? 'defaultColor',
            borderWidth: colorMode == 'dark' ? 1 : 0,
            borderColor: colorMode == 'dark' ? '#fff' : 'none',
            marginTop: 10
          }
        ]}
        onPress={async () => {
          navigation.navigate('TabNavigator', {
            screen: 'Login'
          })
        }}
      >
        <Text style={[styles.buttonText]} fontSize='md'>
          Sou cliente fiel
        </Text>

        <FontAwesome
          name='calendar'
          size={18}
          color='#fff'
          style={{ marginLeft: 4 }}
        />
      </TouchableOpacity>
    )
  }

  function boxBotaoAgendar() {
    return (
      <TouchableOpacity
        style={[
          styles.button,
          {
            backgroundColor:
              colorMode == 'dark' ? '#000' : primaryColor ?? 'defaultColor',
            borderWidth: colorMode == 'dark' ? 1 : 0,
            borderColor: colorMode == 'dark' ? '#fff' : 'none'
          }
        ]}
        onPress={async () => {
          navigation.navigate('InicioStack', {
            screen: 'Profissional',
            params: {
              id_empresa: dados_empresa.id
            }
          })
        }}
      >
        <Text style={[styles.buttonText]} fontSize='md'>
          Agendar horário
        </Text>

        <FontAwesome
          name='calendar'
          size={18}
          color='#fff'
          style={{ marginLeft: 4 }}
        />
      </TouchableOpacity>
    )
  }

  return (
    <ScrollView>
      <HeaderInicio
        images={images}
        isMobile={isMobile}
        nomeFantasia={dados_empresa.nomeFantasia}
        logo={dados_empresa.logo}
      />

      {/* box do menu */}

      {/* comentado até ter a versão com os itens */}
      {/*!empresaNaoEncontrada && !dados_empresa.utilizaLayoutV2 && (
        <Menu
          onPress={() => {
            setTextoModal('Disponível em breve!')
            refModal.current.abrirModal()
          }}
          itens_menu={itens_menu}
        />
      )*/}

      {/* Box do conteúdo */}

      {!dados_empresa.id && !empresaNaoEncontrada ? (
        <SkeletonInicio />
      ) : !empresaNaoEncontrada ? (
        <Box
          style={[
            styles.container,
            {
              backgroundColor: backgroundColor
            }
          ]}
        >
          <Animated.View
            style={[
              styles.container,
              {
                transform: [{ translateX: shakeAnim }] // Aplica o efeito de deslocamento
              }
            ]}
          >
            <Card
              style={{
                margin: 10,
                marginTop: 20,
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${BannerInicio})`
              }}
            >
              <Text
                fontSize='xl'
                style={{
                  fontWeight: '900',
                  letterSpacing: 1
                }}
              >
                <center>{dados_empresa.frase}</center>
              </Text>
            </Card>
          </Animated.View>
          {boxBotaoAgendar()}

          {exibeBotaoAgendarPacote && boxBotaoAgendarClienteFiel()}

          {dados_empresa.urlMaps != '' && boxMaps()}

          <Footer
            isMobile={isMobile}
            versao={packageJson.version}
            url_instagram={dados_empresa.instagramurl}
            url_facebook={dados_empresa.facebookurl}
          />
        </Box>
      ) : (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 10
          }}
        >
          <Image
            source={imageNaoEncontrada}
            alt='Logo'
            resizeMode='cover'
            style={{
              width: 200,
              height: 200
            }}
          />
          <Alerta
            status='warning'
            message='<b>Não foi possível encontrar a empresa</b>. <br /> Por favor, tente novamente mais tarde'
            messageHtml
          />
        </View>
      )}

      <ModalConfirmacao
        titulo={'Atenção'}
        ref={refModal}
        mensagem={textoModal}
        textoBotaoPositivo={'OK'}
        onConfirmPositivo={() => {
          if (refModal.current) {
            refModal.current.fecharModal()
          }
        }}
      />
    </ScrollView>
  )
}

export default Layout1

{
  /* <Avaliacoes 
avaliacoes={[
    {
        url: 'https://image.freepik.com/fotos-gratis/retrato-de-homem-branco-isolado_53876-40306.jpg',
        nota: 5,
        nome_cliente: "Adriel Dias",
        comentario: "Excelente atendimento"
    },
    
]}
/> */
}
