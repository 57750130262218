import { Box, Flex, Image, Text, useColorMode } from 'native-base'
import React, { useState, useRef, useEffect } from 'react'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import styles from './styles'
import { useTheme } from '../../../../ThemeProvider'

import Api from '../../../../Api/Api'
import { useNavigation, useRoute } from '@react-navigation/native'
import Template from '../../../template/Index'
import Input from '../../../../components/Input'
import { FlatList } from 'react-native'
import { TouchableOpacity } from 'react-native'
import SkeletonItens from '../../../components/Skeleton/Itens'
import { AntDesign } from '@expo/vector-icons'
import StorageUtil from '../../../../Utils'
import ModalConfirmacao from '../../../../components/ModalConfirmacao'

const Historico: React.FC = () => {
  const { getCodEmpresa, configEmpresa } = useTheme()

  const navigation = useNavigation()
  const refModal = useRef<any>(null)
  const refModalConfirmacao = useRef<any>(null)
  // const route = useRoute();

  const [historico, setHistorico] = useState<any[]>([])
  // const [pacotes_original, setPacotesOriginal] = useState<any[]>([]);
  // const [filtro, setFiltro] = useState<String>("");
  const [buscando, setBuscando] = useState<boolean>(false)
  const [agendamentoCancelar, setAgendamentoCancelar] = useState<any>({})
  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa)
  )

  const { colorMode } = useColorMode()

  useEffect(() => {
    getHistoricoCliente()
  }, [])

  async function getHistoricoCliente() {
    const value = await StorageUtil.getItem('cliente')

    if (!value) {
      return
    }
    setBuscando(true)

    const historicoCliente = await Api.historicoCliente(value.id, getCodEmpresa)

    if (!historicoCliente.status) {
      refModal.current.abrirModal('Erro ao buscar histórico')
      setBuscando(false)
      return
    }

    setHistorico(historicoCliente.data)
    setBuscando(false)
  }

  function recuperarData(data: string) {
    const data_formatada = new Date(`${data}T00:00:00`)
    const dia = data_formatada.getDate()
    const mes = data_formatada.getMonth() + 1
    const ano = data_formatada.getFullYear()

    const mesExtenso = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez'
    ]

    return {
      dia: dia < 10 ? `0${dia}` : dia,
      mes: mesExtenso[mes - 1],
      ano: ano
    }
  }

  const renderLinha = (label: string, valor: string) => {
    return (
      <Text
        numberOfLines={1}
        ellipsizeMode='tail'
        style={{
          fontWeight: 'bold',
          color: getConfigEmpresa.coresPWA.corFontePrimaria
        }}
        fontSize='sm'
      >
        {label}:{' '}
        <Text
          style={{
            fontWeight: 'normal',
            color: getConfigEmpresa.coresPWA.corFontePrimaria
          }}
          fontSize='md'
        >
          {' '}
          {valor}
        </Text>
      </Text>
    )
  }

  function linhaPacote(item: any) {
    const { dia, mes, ano } = recuperarData(item.data)

    return (
      <Flex style={[styles.containerFlex, {}]}>
        <Box
          style={{
            width: wp('20%'),
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#1976d2',
            borderRadius: 100,
            padding: 1
          }}
        >
          <Text style={{ color: 'white' }} fontSize='md'>
            {dia}
          </Text>
          <Text style={{ color: 'white', fontWeight: 'bold' }} fontSize='lg'>
            {mes}
          </Text>
          <Text style={{ color: 'white', paddingBottom: 3 }} fontSize='md'>
            {ano}
          </Text>
        </Box>

        <Box
          style={{
            width: wp('60%'),
            paddingLeft: 10
          }}
        >
          {renderLinha('Horário', item.horario)}
          {renderLinha('Profissional', item.nomeProfissional)}
          {renderLinha('Serviço', item.servico)}
          {renderLinha('Status', item.status)}
          {renderLinha(
            'Valor',
            `R$ ${item.valor ? item.valor.toFixed(2).replace('.', ',') : 0}`
          )}
        </Box>

        <TouchableOpacity
          style={{
            width: wp('15%'),
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onPress={() => {
            if (item.status != 'CONFIRMADO') {
              return
            }

            if (refModalConfirmacao.current) {
              setAgendamentoCancelar(item)
              refModalConfirmacao.current.abrirModal()
            }
          }}
        >
          <AntDesign
            name='closecircle'
            size={40}
            color={
              item.status == 'CONFIRMADO'
                ? 'rgba(255, 0, 0, 1)'
                : 'rgba(255, 0, 0, 0.3)'
            }
          />
        </TouchableOpacity>
      </Flex>
    )
  }

  const renderItem = ({ item, index }: any) => (
    <Box
      key={index}
      style={[
        styles.pressableItem,
        {
          // { borderColor: item.selecionado ? cor_rgba ?? 'defaultColor' : 'rgba(0, 0, 0, 0.2)',
          borderColor: getConfigEmpresa.coresPWA.corBordaItens
        } //mais clara
      ]}
    >
      {buscando ? <SkeletonItens /> : linhaPacote(item)}
    </Box>
  )

  const renderEmptyComponent = () => (
    <Text style={{ textAlign: 'center', marginTop: 20 }} fontSize='md'>
      Nenhum registro encontrado.
    </Text>
  )

  async function cancelarAgendamento() {
    if (!refModal.current) {
      return
    }

    refModal.current.abrirLoader()
    const cancelar = await Api.cancelarAgendamento(agendamentoCancelar.id)

    if (!cancelar.status) {
      refModal.current.abrirModal('Erro ao cancelar agendamento')
      refModal.current.fecharLoader()
      refModalConfirmacao.current.fecharModal()
      return
    }

    refModalConfirmacao.current.fecharModal()
    refModal.current.fecharLoader()
    getHistoricoCliente()
  }

  return (
    <Template
      titulo={'Meus Agendamentos'}
      messageAlerta='Nesta tela você pode visualizar os seus agendamentos'
      semBotaoProximo
      onBotaoVoltarClick={() => {
        navigation.goBack()
      }}
      ref={refModal}
    >
      <Box style={styles.mainContainer}>
        <FlatList
          data={historico}
          renderItem={renderItem}
          ListEmptyComponent={
            buscando ? <SkeletonItens /> : renderEmptyComponent
          }
          keyExtractor={(item, index) => index.toString()}
          contentContainerStyle={{
            width: wp('98%')
          }}
        />
      </Box>

      <ModalConfirmacao
        titulo={'Atenção'}
        ref={refModalConfirmacao}
        mensagem={'Tem certeza que deseja cancelar o agendamento?'}
        textoBotaoPositivo={'Confirmar'}
        textoBotaoNegativo={'Cancelar'}
        onConfirmPositivo={() => {
          if (refModal.current) {
            cancelarAgendamento()
          }
        }}
        onConfirmNegativo={() => {
          if (refModalConfirmacao.current) {
            refModalConfirmacao.current.fecharModal()
          }
        }}
      />
    </Template>
  )
}

export default Historico
