import { Stack, Alert, VStack, HStack, Text } from 'native-base'

export interface AlertProps {
  status: 'success' | 'error' | 'warning' | 'info'
  message: string
  variant?: string
  messageHtml?: boolean
}

export default function Alerta({
  status,
  message,
  variant,
  messageHtml
}: Readonly<AlertProps>) {
  return (
    <Stack space={3} w='100%' padding={2}>
      <Alert w='100%' variant={variant || 'top-accent'} status={status}>
        <VStack space={2} flexShrink={1} w='100%'>
          <HStack flexShrink={1} space={2} justifyContent='space-between'>
            <HStack space={2} flexShrink={1}>
              <Alert.Icon mt='1' />
              <Text fontSize='md' color='coolGray.800'>
                {messageHtml ? (
                  <div dangerouslySetInnerHTML={{ __html: message }} />
                ) : (
                  message
                )}
              </Text>
            </HStack>
          </HStack>
        </VStack>
      </Alert>
    </Stack>
  )
}
