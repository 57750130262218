import React, { useState, useImperativeHandle, useRef } from 'react'
import {
  Input,
  Text,
  Icon,
  Pressable,
  Box,
  Button,
  AlertDialog,
  View,
  WarningTwoIcon
} from 'native-base'
import { RFPercentage } from 'react-native-responsive-fontsize'
import Modal from 'react-native-modal'
import { TouchableOpacity } from 'react-native'
import { useTheme } from '../../ThemeProvider.tsx'

interface BotoesRodapeProps {
  onConfirmNegativo?: () => void
  onConfirmPositivo: () => void
  titulo?: string
  mensagem?: string
  textoBotaoNegativo?: string
  textoBotaoPositivo?: string
  fecharModal?: () => void
}

function ModalConfirmacao(
  {
    titulo,
    mensagem,
    textoBotaoNegativo,
    textoBotaoPositivo,
    onConfirmNegativo,
    onConfirmPositivo
  }: BotoesRodapeProps,
  ref: any
) {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const { isMobile, fontSizeMobile, fontSizeDesktop, configEmpresa } =
    useTheme()

  // antes de fazer o json.parse, verificar se o configEmpresa é um objeto

  let backgroundColor = '#fff'
  let corTexto = '#000'
  let corBorda = '#000'

  if (typeof configEmpresa === 'string') {
    const coresPWA = JSON.parse(configEmpresa).coresPWA
    backgroundColor = coresPWA.corFundoModal
    corTexto = coresPWA.corFontePrimaria
    corBorda = coresPWA.corBordaPrimaria
  }
  const cancelRef = useRef<any>(null)

  function abrirModal() {
    setModalVisible(true)
  }

  function fecharModal() {
    setModalVisible(false)
  }

  useImperativeHandle(ref, () => ({ abrirModal, fecharModal }))

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={modalVisible}
      onClose={() => fecharModal()}
    >
      <AlertDialog.Content borderColor={corBorda}>
        <AlertDialog.CloseButton />
        <AlertDialog.Header
          backgroundColor={backgroundColor}
          color={corTexto}
          style={{ borderColor: corBorda }}
        >
          <Text style={{ color: corTexto }} fontSize='xl'>
            <WarningTwoIcon /> <b>{titulo}</b>
          </Text>
        </AlertDialog.Header>
        <AlertDialog.Body backgroundColor={backgroundColor}>
          <Text
            style={{ textAlign: '-webkit-center', color: corTexto }}
            fontSize='md'
          >
            {mensagem}
          </Text>
        </AlertDialog.Body>
        <AlertDialog.Footer
          backgroundColor={backgroundColor}
          style={{ borderColor: corBorda }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            {textoBotaoNegativo && (
              <TouchableOpacity
                onPress={() => {
                  if (onConfirmNegativo) {
                    onConfirmNegativo()
                  }
                }}
                style={{
                  backgroundColor: 'red',
                  padding: 10,
                  borderRadius: 8
                }}
              >
                <Text
                  style={{
                    color: '#fff',
                    marginRight: 9
                  }}
                  fontSize='lg'
                >
                  {' '}
                  {textoBotaoNegativo}{' '}
                </Text>
              </TouchableOpacity>
            )}

            <TouchableOpacity
              onPress={() => onConfirmPositivo()}
              style={{
                backgroundColor: 'green',
                padding: 10,
                borderRadius: 8,
                marginLeft: 5
              }}
            >
              <Text
                style={{
                  color: '#fff',
                  marginRight: 9
                }}
                fontSize='lg'
              >
                {' '}
                {textoBotaoPositivo}{' '}
              </Text>
            </TouchableOpacity>
          </View>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  )
}

export default React.forwardRef(ModalConfirmacao)
