import React, { useRef, useEffect, useImperativeHandle, useState } from 'react'
import { ScrollView, TouchableOpacity } from 'react-native'
import {
  Text,
  Box,
  Button,
  Flex,
  Center,
  Avatar,
  VStack,
  Image,
  AspectRatio,
  useColorMode,
  useColorModeValue
} from 'native-base'

import { useNavigation } from '@react-navigation/native'

import createStyles from './styles'

import Modal from '../../components/ModalPersonalizado'
import { RFPercentage } from 'react-native-responsive-fontsize'
import { useTheme } from '../../ThemeProvider.tsx'
import Loader from '../../components/Loader'
import ModalConfirmacao from '../../components/ModalConfirmacao'
import { Entypo } from '@expo/vector-icons'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { widthPercentageToDP } from 'react-native-responsive-screen'
import Menu from '../pages/Inicio/Layout2/Menu/Index'
import { TitleItem } from '../components/Title/Index'
import Alerta from '../components/Alerta/Index'

interface Props {
  titulo: string
  messageAlerta?: string
  urlLogo?: string
  exibeLogo?: boolean
  exibeMenuSuperior?: boolean
  titulo_botao_proximo?: string
  titulo_botao_voltar?: string
  icon_voltar?: string
  textoCentroFooter?: string
  disabled_button_proximo?: boolean
  onBotaoProximoClick?: () => void
  /**
   * Função chamada quando o botão "Próximo" é clicado.
   * @param id O ID associado ao botão.
   * @returns Verdadeiro se a ação foi bem sucedida, falso caso contrário.
   */
  onBotaoVoltarClick?: () => void
  children?: React.ReactNode
  filtro?: React.ReactNode
  escondeBotoesRodape?: boolean
  abrirModal?: boolean
  semBotaoProximo?: boolean
  semBotaoVoltar?: boolean
}

interface BotoesRodapeProps {
  onBotaoVoltarClick?: () => void
  onBotaoProximoClick?: () => void
  titulo_botao_proximo?: string
  titulo_botao_voltar?: string
  icon_voltar?: string
  textoCentroFooter?: string
  disabled_button_proximo?: boolean
  semBotaoProximo?: boolean
  semBotaoVoltar?: boolean
}

function Template(
  {
    titulo,
    urlLogo,
    exibeLogo,
    exibeMenuSuperior,
    titulo_botao_proximo,
    titulo_botao_voltar,
    messageAlerta,
    icon_voltar,
    textoCentroFooter,
    onBotaoProximoClick,
    onBotaoVoltarClick,
    children,
    filtro,
    escondeBotoesRodape,
    disabled_button_proximo,
    semBotaoProximo,
    semBotaoVoltar
  }: Props,
  ref: any
) {
  const {
    primaryColor,
    cor_rgba,
    isMobile,
    fontSizeMobile,
    fontSizeDesktop,
    configEmpresa
  } = useTheme()

  const refModal = useRef<any>(null) // Certifique-se de que a inicialização está correta
  const refLoader = useRef()
  const [tituloModal, setTituloModal] = useState<string>('Atenção')
  const [textoModal, setTextoModal] = useState<string>('')
  const [abrirLoader, setAbrirLoader] = useState<boolean>(false)
  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa)
  )

  const styles = createStyles(getConfigEmpresa.coresPWA) // gere os estilos dinâmicos

  function BotoesRodape({
    onBotaoVoltarClick,
    onBotaoProximoClick,
    titulo_botao_proximo,
    titulo_botao_voltar,
    icon_voltar,
    textoCentroFooter,
    disabled_button_proximo,
    semBotaoProximo,
    semBotaoVoltar
  }: BotoesRodapeProps) {
    const navigation = useNavigation()
    return (
      <Flex
        style={[
          styles.flexContainer,
          {
            justifyContent:
              !semBotaoVoltar && !semBotaoProximo
                ? 'space-between'
                : 'space-between'
          }
        ]}
      >
        {/* <Button  */}
        {!semBotaoVoltar && (
          <TouchableOpacity
            onPress={() => {
              onBotaoVoltarClick ? onBotaoVoltarClick() : navigation.goBack()
            }}
            style={[styles.button, { backgroundColor: '#6D6D6D' }]}
          >
            <Entypo
              name={icon_voltar ? icon_voltar : 'arrow-with-circle-left'}
              size={18}
              color='#fff'
              style={{ marginRight: 4 }}
            />
            <Text style={styles.buttonText} fontSize='md'>
              {titulo_botao_voltar ? titulo_botao_voltar : 'Voltar'}
            </Text>
          </TouchableOpacity>
        )}

        {textoCentroFooter && (
          <Text style={styles.textFooter} fontSize='md'>
            {textoCentroFooter}
          </Text>
        )}
        {/* alterado fonte */}

        {!semBotaoProximo && (
          <TouchableOpacity
            disabled={disabled_button_proximo}
            onPress={onBotaoProximoClick}
            style={[
              styles.button,
              {
                backgroundColor: disabled_button_proximo
                  ? cor_rgba ?? 'defaultColor'
                  : primaryColor ?? 'defaultColor'
              }
            ]}
          >
            <Text style={styles.buttonText} fontSize='md'>
              {titulo_botao_proximo || 'Próximo'}
            </Text>

            {titulo_botao_proximo == 'Agendar' ? (
              <MaterialCommunityIcons
                name='check-circle-outline'
                size={18}
                color='#fff'
                style={{ marginLeft: 4 }}
              />
            ) : (
              <Entypo
                name='arrow-with-circle-right'
                size={18}
                color='#fff'
                style={{ marginLeft: 4 }}
              />
            )}
          </TouchableOpacity>
        )}
      </Flex>
    )
  }

  useImperativeHandle(ref, () => ({
    abrirModal(mensagem: string, titulo: string) {
      setTituloModal(titulo ? titulo : 'Atenção')
      setTextoModal(mensagem)
      refModal.current.abrirModal()
    },
    fecharModal() {
      refModal.current.fecharModal()
    },
    abrirLoader() {
      setAbrirLoader(true)
      // refLoader.current.abrirLoader();
    },
    fecharLoader() {
      setAbrirLoader(false)
      // refLoader.current.fecharLoader();
    }
  }))

  return (
    <Box style={styles.boxGeral}>
      {/* <Button onPress={toggleColorMode}>Toggle</Button> */}

      {exibeLogo ? (
        <Box style={styles.boxBanner}>
          <Image
            alt='Imagem de fundo do banner'
            source={{ uri: urlLogo || '' }}
            style={{ width: widthPercentageToDP('100%'), height: '100%' }}
            resizeMode='cover'
          />
        </Box>
      ) : null}

      {exibeMenuSuperior ? <Menu /> : null}

      <Box style={[styles.boxGeralTitulo]}>
        {/* <Box style={[styles.boxGeralTitulo, { borderBottomColor: colorMode == 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.2)' }]}> */}
        <TitleItem title={titulo} />
      </Box>

      {messageAlerta && <Alerta message={messageAlerta} status='info' />}

      {filtro ? (
        <Box style={styles.boxGeralScrollViewFiltro}>{filtro}</Box>
      ) : null}

      <ScrollView>
        <Box style={styles.boxGeralScrollView}>
          <Box style={styles.boxGeralScrollViewBox}>{children}</Box>
        </Box>
      </ScrollView>
      {escondeBotoesRodape ? null : (
        <BotoesRodape
          onBotaoVoltarClick={onBotaoVoltarClick}
          onBotaoProximoClick={onBotaoProximoClick}
          titulo_botao_proximo={titulo_botao_proximo}
          titulo_botao_voltar={titulo_botao_voltar}
          icon_voltar={icon_voltar}
          textoCentroFooter={textoCentroFooter}
          disabled_button_proximo={disabled_button_proximo}
          semBotaoProximo={semBotaoProximo}
          semBotaoVoltar={semBotaoVoltar}
        />
      )}

      <ModalConfirmacao
        titulo={tituloModal}
        ref={refModal}
        mensagem={textoModal}
        textoBotaoPositivo={'OK'}
        onConfirmPositivo={() => {
          if (refModal.current) {
            refModal.current.fecharModal()
          }
        }}
      />

      {abrirLoader && <Loader ref={refLoader} />}
    </Box>
  )
}

export default React.forwardRef(Template)
