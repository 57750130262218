import React, { useEffect, useRef, useState } from 'react'
import { TouchableOpacity } from 'react-native'
import { Box, Flex, Text } from 'native-base'
import Input from '../../../../components/Input'

import { useNavigation, useRoute } from '@react-navigation/native'
import Api from '../../../../Api/Api'
import styles from './styles'
import Template from '../../../template/Index.tsx'
import StorageUtil from '../../../../Utils'
import { useTheme } from '../../../../ThemeProvider.tsx'
import { Entypo } from '@expo/vector-icons'
import ModalConfirmacao from '../../../../components/ModalConfirmacao'

export default function DadosUsuario({}) {
  const { primaryColor, getCodEmpresa, configEmpresa } = useTheme()

  const navigation = useNavigation()
  const route = useRoute()

  const [cleaned, setCleaned] = useState('')
  const [cliente, setCliente] = useState<any>({})
  const [clienteSimples, setClienteSimples] = useState<any>({})
  const [tipoOperacao, setTipoOperacao] = useState<number>(1)
  // 1 entrar normal,
  // 2 cadastrar,
  // 3 login com senha,
  // 4 alterar senha,
  // 5 informar data de nascimento,
  // 6 agendar sem cadastro
  // 7 utiliza sistema apenas com telefone e nome - mas cadastra caso não exista
  const [nome, setNome] = useState<String>('')
  const [senha, setSenha] = useState<String>('')
  const [confirmaSenha, setConfirmaSenha] = useState<String>('')
  const [telefone, setTelefone] = useState<String>('')
  const [dataNascimento, setDataNascimento] = useState<String>('')
  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa)
  )
  const [numeroInternacional, setNumeroInternacional] = useState<boolean>(false)
  const [limparDados, setLimparDados] = useState<boolean>(false)

  const refModal = useRef()

  const confirmationRef = useRef<any>(null)

  useEffect(() => {
    getClients()
  }, [])

  async function getClients() {
    const value = await StorageUtil.getItem('cliente')

    if (value && value.id > 0) {
      if (getConfigEmpresa.naoSolicitarDadosCompletosPWA) {
        await buscarClienteSoTelefone(value.telefone)
      } else if (!value.senha) {
        return
      } else {
        await buscarClienteSenha(value.telefone, value.senha, value.loginAdmin)
      }
    }
  }

  function onchange(text: String) {
    if (numeroInternacional) {
      setTelefone(text)
      return
    }

    text = text.replace(/\D/g, '')

    let newCleaned = text.replace(/\D/g, '').substring(0, 11) // remove non-digit and limit
    if (newCleaned !== cleaned) {
      setCleaned(newCleaned)

      let formatted = newCleaned
      if (newCleaned.length >= 2) {
        formatted = `(${newCleaned.substring(0, 2)}) ${newCleaned.substring(2)}`
      }
      if (newCleaned.length >= 7) {
        formatted = `(${newCleaned.substring(0, 2)}) ${newCleaned.substring(
          2,
          7
        )}-${newCleaned.substring(7)}`
      }

      setTelefone(formatted)
    } else {
      setTelefone(text)
    }
  }

  function onchangeDtNascimento(text: String) {
    text = text.replace(/\D/g, '')

    let newCleaned = text.replace(/\D/g, '').substring(0, 8) // remove non-digit and limit
    if (newCleaned !== cleaned) {
      setCleaned(newCleaned)

      let formatted = newCleaned
      if (newCleaned.length >= 2) {
        formatted = `${newCleaned.substring(0, 2)}/${newCleaned.substring(2)}`
      }
      if (newCleaned.length >= 4) {
        formatted = `${newCleaned.substring(0, 2)}/${newCleaned.substring(
          2,
          4
        )}/${newCleaned.substring(4)}`
      }

      setDataNascimento(formatted)
    } else {
      setDataNascimento(text)
    }
  }

  async function validarLogin() {
    const value = await StorageUtil.getItem('cliente')

    if (value) {
      setNome(value.nome)
      setTelefone(value.telefone)
    }
  }

  function proximaPagina(cliente: any) {
    navigation.navigate('Confirmacao', {
      ...route.params,
      cliente,
      validarLogin: validarLogin
    })
  }

  async function buscarClienteSenha(
    telefone_?: string,
    senha_?: string,
    loginAdmin?: boolean
  ) {
    const telefone_limpo = (telefone_ ? telefone_ : telefone).replace(/\D/g, '')

    if (!isTelefoneValido(telefone_limpo)) {
      abrirModal('Informe um telefone válido')
      return
    }

    exibirLoader()

    // depois melhorar isso aqui, se o loginAdmin for true, solicitar o login do admin, deverá na tela anterior salvar o login do admin tbm.
    const cliente = await Api.buscar_cliente(
      telefone_ ? telefone_ : telefone,
      senha_ ? senha_ : senha,
      loginAdmin ? loginAdmin : false
    )

    if (!cliente.status) {
      fecharLoader()
      abrirModal(
        cliente.error_details
          ? cliente.error_details.message
          : 'Erro ao buscar cliente'
      )
      return
    }

    if (cliente.data == '') {
      setTipoOperacao(1)
      setNome('')
      setSenha('')
      setDataNascimento('')
      //  await StorageUtil.removeItem('cliente');

      fecharLoader()
      return
    }

    const dados_cliente = cliente.data

    if (dados_cliente.alterarSenha) {
      await StorageUtil.removeItem('cliente')

      setTipoOperacao(4)
      fecharLoader()
      setCliente(dados_cliente)
      setTelefone(dados_cliente.telefone)
      abrirModal('Informe uma nova senha')
      return
    }

    dados_cliente.senha = senha_ ? senha_ : senha

    await StorageUtil.setItem('cliente', dados_cliente)

    fecharLoader()

    if (!dados_cliente.dtNascimento) {
      setTelefone(dados_cliente.telefone)
      setCliente(dados_cliente)
      setTipoOperacao(5)
      abrirModal('Para continuar informe sua data de nascimento')
      return
    }

    proximaPagina(dados_cliente)
  }

  async function alterarDadosCliente() {
    if (dataNascimento.length < 10) {
      abrirModal('Informe uma data de nascimento válida')
      return
    }

    exibirLoader()

    const resultadoAlteracao = await Api.alterarDadosCliente(
      cliente.id,
      dataNascimento
    )

    if (!resultadoAlteracao.status) {
      fecharLoader()
      abrirModal(
        resultadoAlteracao.error_details.message || 'Erro ao alterar dados'
      )
      return
    }

    if (!resultadoAlteracao.data) {
      abrirModal('Erro ao alterar dados')
      return
    }

    await buscarClienteSenha(cliente.telefone, cliente.senha)
  }

  async function alterarSenha() {
    if (senha != confirmaSenha) {
      abrirModal('As senhas não conferem')
      return
    }
    exibirLoader()

    const resultadoAlteracao = await Api.alterar_senha(cliente.id, senha)

    if (!resultadoAlteracao.status) {
      fecharLoader()
      abrirModal(
        resultadoAlteracao.error_details.message || 'Erro ao alterar senha'
      )
      return
    }

    const { telefone } = resultadoAlteracao.data

    await buscarClienteSenha(telefone, senha)
  }

  async function cadastrarCliente() {
    const telefone_limpo = telefone.replace(/\D/g, '')

    if (!isTelefoneValido(telefone_limpo)) {
      abrirModal('Informe um telefone válido')
      return
    }

    if (!isNomeValido(nome)) {
      abrirModal('Informe seu nome completo')
      return
    }

    if (senha != confirmaSenha) {
      abrirModal('As senhas não conferem')
      return
    }

    if (senha == '' || confirmaSenha == '') {
      abrirModal('Informe uma senha')
      return
    }

    if (dataNascimento.length < 10) {
      abrirModal('Informe uma data de nascimento válida')
      return
    }

    const dataNascimentoSplit = dataNascimento.split('/')
    const dia = parseInt(dataNascimentoSplit[0])
    const mes = parseInt(dataNascimentoSplit[1])
    const ano = parseInt(dataNascimentoSplit[2])

    if (dia < 1 || dia > 31) {
      abrirModal('Informe um dia de nascimento válido')
      return
    }

    if (mes < 1 || mes > 12) {
      abrirModal('Informe um mês de nascimento válido')
      return
    }

    if (ano < 1930) {
      abrirModal('Informe um ano de nascimento válido')
      return
    }

    exibirLoader()
    const resultadoCadastro = await Api.cadastrar_cliente(
      telefone,
      nome,
      getCodEmpresa,
      senha,
      dataNascimento
    )

    if (!resultadoCadastro.status) {
      fecharLoader()
      abrirModal(
        resultadoCadastro.error_details.message || 'Erro ao cadastrar cliente'
      )
      return
    }

    const dados_cliente = resultadoCadastro.data
    dados_cliente.senha = senha

    await StorageUtil.setItem('cliente', dados_cliente)

    fecharLoader()
    proximaPagina(dados_cliente)
  }

  async function cadastrarClienteTelefoneNome() {
    const telefone_limpo = telefone.replace(/\D/g, '')

    if (!isTelefoneValido(telefone_limpo)) {
      abrirModal('Informe um telefone válido')
      return
    }

    if (!isNomeValido(nome)) {
      abrirModal('Informe seu nome completo')
      return
    }

    exibirLoader()
    const resultadoCadastro = await Api.cadastrar_cliente(
      telefone,
      nome,
      getCodEmpresa
    )

    if (!resultadoCadastro.status) {
      fecharLoader()
      abrirModal(
        resultadoCadastro.error_details.message || 'Erro ao cadastrar cliente'
      )
      return
    }

    const dados_cliente = resultadoCadastro.data
    await StorageUtil.setItem('cliente', dados_cliente)

    fecharLoader()
    proximaPagina(dados_cliente)
  }

  async function agendarSemCadastro() {
    if (!isNomeValido(nome)) {
      abrirModal('Informe seu nome completo')
      return
    }

    exibirLoader()

    const resultadoAgendamento = await Api.buscarClienteFinal(getCodEmpresa)

    if (!resultadoAgendamento.status || resultadoAgendamento.data == '') {
      fecharLoader()
      abrirModal(
        'Não foi possível agendar sem cadastro, tente novamente mais tarde'
      )
      setNome('')
      setTipoOperacao(1)
      return
    }

    const dados_cliente = resultadoAgendamento.data

    dados_cliente.nome = nome
    fecharLoader()
    proximaPagina(dados_cliente)
  }

  async function buscarClienteSoTelefone(
    telefone_?: String,
    dadosCorretos?: boolean
  ) {
    if (!telefone_) {
      abrirModal('Informe um telefone válido')
      return
    }

    const telefone_limpo = telefone_.replace(/\D/g, '')

    if (!isTelefoneValido(telefone_limpo)) {
      abrirModal('Informe um telefone válido')
      return
    }

    const cliente = await Api.buscar_cliente(telefone_)

    if (!cliente.status) {
      fecharLoader()
      abrirModal('Erro ao buscar cliente')
      return
    }

    if (cliente.data == '') {
      // se não existe o cliente mais então manda logar normal
      setTipoOperacao(1)
      setNome('')
      setClienteSimples({})
      await StorageUtil.removeItem('cliente')
      return
    } else if (dadosCorretos) {
      // aqui é quando o cliente já confirmou que ta correto o nome
      await StorageUtil.setItem('cliente', cliente.data)
      proximaPagina(cliente.data)
      return
    } else {
      // quando o cliente ta cadastrado e então manda confirma o nome
      setNome(cliente.data.nome)
      setTelefone(cliente.data.telefone)
      setTipoOperacao(7)
      setClienteSimples(cliente.data)
      abrirModal('Confirme se seu nome está correto')
    }
    fecharLoader()
  }

  async function proximo() {
    // se a config agendaSemCadastro estiver ativa, e o tipo de operação for 6
    if (tipoOperacao == 6) {
      agendarSemCadastro()
      return
    }

    const telefone_limpo = telefone.replace(/\D/g, '')

    if (!isTelefoneValido(telefone_limpo)) {
      abrirModal('Informe um telefone válido')
      return
    }

    if (tipoOperacao == 5) {
      // informar data de nascimento
      alterarDadosCliente()
      return
    }

    if (tipoOperacao == 2) {
      // cadastrar cliente
      cadastrarCliente()
      return
    }
    if (tipoOperacao == 7 && !clienteSimples.id) {
      // cadastrar cliente só com telefone e nome
      cadastrarClienteTelefoneNome()
      return
    }

    if (tipoOperacao == 7 && clienteSimples.id) {
      // busca cliente só com telefone e nome
      buscarClienteSoTelefone(telefone, true)
      return
    }
    if (tipoOperacao == 3) {
      // login normal com senha
      buscarClienteSenha()
      return
    }
    if (tipoOperacao == 4) {
      // alterar senha
      alterarSenha()
      return
    }

    // se passou por tudo então o cara ta digitando o telefone pela primeira vez.

    exibirLoader()

    const cliente = await Api.buscar_cliente(telefone)

    if (!cliente.status) {
      fecharLoader()
      abrirModal('Erro ao buscar cliente')
      return
    }

    if (getConfigEmpresa.naoSolicitarDadosCompletosPWA) {
      if (cliente.data != '') {
        setNome(cliente.data.nome)
        setTelefone(cliente.data.telefone)
        setClienteSimples(cliente.data)
        abrirModal('Confirme se seu nome está correto')
      }

      setTipoOperacao(7)
    } else if (cliente.data == '') {
      setTipoOperacao(2)
    } else if (cliente.data.alterarSenha) {
      setTipoOperacao(4)
      abrirModal('Informe uma nova senha')
    } else if (cliente.data.id > 0) {
      setTipoOperacao(3)
    }

    setCliente(cliente.data)
    fecharLoader()
  }

  function abrirModal(texto: String) {
    if (refModal.current) {
      refModal.current.abrirModal(texto)
    }
  }

  function isTelefoneValido(telefone: String) {
    if (numeroInternacional) {
      return true
    }

    return telefone.length >= 11
  }

  function isNomeValido(nome: String) {
    return nome.length >= 4 && nome.indexOf(' ') !== -1
  }

  function exibirLoader() {
    refModal.current.abrirLoader()
  }
  function fecharLoader() {
    refModal.current.fecharLoader()
  }

  function voltar() {
    navigation.goBack()
  }

  function renderBotoesRodape() {
    return (
      <Flex style={styles.flexContainer}>
        <TouchableOpacity
          // <Button
          style={[styles.button, { backgroundColor: '#6D6D6D' }]}
          onPress={() => {
            voltar()
          }}
        >
          <Entypo
            name='arrow-with-circle-left'
            size={18}
            color='#fff'
            style={{ marginRight: 4 }}
          />
          <Text style={styles.buttonText} fontSize='md'>
            {' '}
            Voltar
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.button,
            { backgroundColor: primaryColor ?? 'defaultColor' }
          ]}
          onPress={async () => {
            proximo()
          }}
        >
          <Text style={styles.buttonText} fontSize='md'>
            Próximo
          </Text>

          <Entypo
            name='arrow-with-circle-right'
            size={18}
            color='#fff'
            style={{ marginLeft: 4 }}
          />
        </TouchableOpacity>
      </Flex>
    )
  }

  async function onfocus() {
    // setTelefone("");
    // setDesabilitar(false);
    // setSolicita_nome(false);
    // setCleaned("");
    // setNome("");
    // await StorageUtil.removeItem('cliente');
  }

  function confirmarNacionalidadeNumero() {
    confirmationRef.current.fecharModal()
    setNumeroInternacional(!numeroInternacional)

    if (!numeroInternacional) {
      abrirModal('Informe apenas os números do seu telefone')
    }

    setTelefone('')
  }

  return (
    <Template
      titulo={'Informe Seus Dados'}
      messageAlerta='Para finalizar o agendamento, informe seus dados'
      // disabled_button_proximo={mensagemRodape != ''}
      escondeBotoesRodape
      ref={refModal}
    >
      <Box style={styles.mainContainer}>
        {/* se for tipo 6 não pede telefone */}

        {getConfigEmpresa.removerValidacaoTelefone && (
          <TouchableOpacity
            onPress={() => {
              confirmationRef.current.abrirModal()
            }}
          >
            <Text
              fontSize={'md'}
              style={{
                marginBottom: 25,
                fontWeight: 'bold',
                color: getConfigEmpresa.coresPWA.corFontePrimaria
              }}
            >
              Número {numeroInternacional ? 'Nacional' : 'Internacional'} ?
            </Text>
          </TouchableOpacity>
        )}

        {tipoOperacao != 6 && (
          <Input
            colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
            corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
            width={'100%'}
            placeholder={'Telefone'}
            helperText='Informe o seu telefone'
            value={telefone}
            icon={'call'}
            onFocus={onfocus}
            type='number'
            onChangeText={(text) => {
              if (text.length > 15) {
                return
              }

              onchange(text)
            }}
          />
        )}

        {/* se for tipo 6 só pede o nome completo */}
        {tipoOperacao == 6 && (
          <Input
            colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
            corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
            value={nome}
            styleBox={{ marginTop: 5 }}
            icon={'call'}
            onChangeText={(text) => {
              setNome(text)
            }}
            width={'100%'}
            placeholder='Nome Completo'
            helperText='Informe seu nome completo'
          />
        )}

        {/* se não tem cliente e o tipo for cadastro mostra esse campo */}
        {!cliente.id && tipoOperacao == 2 && (
          <Input
            colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
            corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
            value={nome}
            styleBox={{ marginTop: 5 }}
            icon={'call'}
            onChangeText={(text) => {
              setNome(text)
            }}
            width={'100%'}
            placeholder='Nome Completo'
            helperText='Informe seu nome completo'
          />
        )}

        {tipoOperacao == 7 && (
          <Input
            colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
            corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
            value={nome}
            styleBox={{ marginTop: 5 }}
            icon={'call'}
            onChangeText={(text) => {
              setNome(text)
            }}
            width={'100%'}
            placeholder='Nome Completo'
            helperText='Informe seu nome completo'
          />
        )}

        {/* se não tem cliente e o tipo for cadastro mostra esse campo */}
        {!cliente.id && tipoOperacao == 2 && (
          <Input
            colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
            corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
            value={dataNascimento}
            styleBox={{ marginTop: 5 }}
            icon={'calendar-today'}
            type='number'
            onChangeText={(text) => {
              if (text.length > 10) {
                return
              }
              onchangeDtNascimento(text)
            }}
            width={'100%'}
            placeholder='EX: DD/MM/AAAA'
            helperText='Informe sua data de nascimento'
          />
        )}

        {/* se não tem cliente e o tipo for cadastro, ou tipo login com senha, ou alterar senha mostra esse campo */}
        {((!cliente.id && tipoOperacao == 2) ||
          tipoOperacao == 3 ||
          tipoOperacao == 4) && (
          <Input
            colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
            corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
            value={senha}
            styleBox={{ marginTop: 5 }}
            icon={'vpn-key'}
            type={'password'}
            // style={{marginTop:10}}
            onChangeText={(text) => {
              setSenha(text)
            }}
            width={'100%'}
            placeholder='Senha'
            helperText='Informe sua senha'
          />
        )}

        {/* se não tem cliente e o tipo for cadastro ou alterar senha mostra esse campo */}
        {((!cliente.id && tipoOperacao == 2) || tipoOperacao == 4) && (
          <Input
            colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
            corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
            value={confirmaSenha}
            styleBox={{ marginTop: 5 }}
            icon={'vpn-key'}
            type={'password'}
            // style={{marginTop:10}}
            onChangeText={(text) => {
              setConfirmaSenha(text)
            }}
            width={'100%'}
            placeholder='Confirma Senha'
            helperText='Confirme sua senha'
          />
        )}

        {/* se o tipo for informar data de nascimento mostra esse campo */}
        {tipoOperacao == 5 && (
          <Input
            colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
            corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
            value={dataNascimento}
            styleBox={{ marginTop: 5 }}
            icon={'calendar-today'}
            type='number'
            onChangeText={(text) => {
              if (text.length > 10) {
                return
              }

              onchangeDtNascimento(text)
            }}
            width={'100%'}
            placeholder='EX: DD/MM/AAAA'
            helperText='Informe sua data de nascimento'
          />
        )}

        {/* se a config estiver ativa mostra esse texto  */}
        {tipoOperacao != 6 && getConfigEmpresa.permiteAgendarSemCadastro && (
          <Box
            style={{
              marginTop: 10
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setTipoOperacao(6)
                abrirModal(
                  'Informe seu nome completo para agendar sem cadastro'
                )
              }}
            >
              <Text fontSize='md'>
                Caso não queira se cadastrar, clique aqui
              </Text>
            </TouchableOpacity>
          </Box>
        )}

        {renderBotoesRodape()}

        {/* ficou invertido as confições pq vai servir tanto para um quanto para outro */}
        <ModalConfirmacao
          ref={confirmationRef}
          titulo={'Atenção'}
          mensagem={`O seu número de telefone é ${
            numeroInternacional ? 'Nacional' : 'Internacional'
          } ?`}
          textoBotaoNegativo={'Não'}
          textoBotaoPositivo={'Sim'}
          onConfirmNegativo={() => confirmationRef.current.fecharModal()}
          onConfirmPositivo={() => confirmarNacionalidadeNumero()}
        />
      </Box>
    </Template>
  )
}
