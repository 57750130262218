import { StyleSheet } from 'react-native'
import { RFPercentage } from 'react-native-responsive-fontsize'
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from 'react-native-responsive-screen'

export default StyleSheet.create({
  container: {
    height: hp('93%'),
    alignItems: 'center'
    // backgroundColor: '#fff',
  },
  header: {
    // height: hp('6%'),
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'row'

    // backgroundColor: '#1976d2',
  },

  textCadastro: {
    marginTop: 10,
    color: 'black',
    fontSize: RFPercentage(2.0) // certifique-se de que RFPercentage seja importado
  },
  boxBanner: {
    width: '100%',
    height: 200,

    borderBottomColor: 'rgba(0, 0, 0, 0.3)',
    borderBottomWidth: 2,
    borderTopColor: 'rgba(0, 0, 0, 0.3)',
    borderTopWidth: 1.5,
    backgroundColor: '#fff'
  },
  boxConteudo: {
    width: '100%'
  },
  boxIconeHeader: {
    width: '90%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxTituloHeader: {
    width: '85%',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 4,
    height: '100%'
  },
  boxTituloHeader2: {
    width: '15%',
    justifyContent: 'center',
    alignItems: 'center'
    // height: '100%',
  },
  tituloHeader: {
    fontWeight: 'bold',
    color: '#fff'
  },
  boxConteudo: {
    width: '100%',
    // height:'70%',
    paddingTop: 10,
    paddingBottom: 10
    // marginLeft: 10,
    // justifyContent: 'center',
    // alignItems: 'center',
    // backgroundColor: '#fff',
  },
  boxIconeItem: {
    width: '100%',
    height: '20%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxTituloItem: {
    width: '100%',
    height: '20%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxDescricaoItem: {
    width: '100%',
    height: '20%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxBotaoItem: {
    width: '100%',
    height: '20%',
    justifyContent: 'center',
    alignItems: 'center'
  },

  containerItem: {
    width: '100%',
    // height: '40%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10
  },

  buttonContainer: {
    width: '40%',
    // height: '100%',
    alignItems: 'center'
  },
  buttonWrapper: {
    width: '100%',
    height: '90%',
    // backgroundColor: '#fff',
    padding: 5,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,

    borderBottomColor: 'rgba(0, 0, 0, 0.9)',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 2
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(144, 238, 144, 0.3)',
    borderRadius: 100,
    padding: 12
  },
  iconBackground: {
    backgroundColor: 'white',
    borderRadius: 100,
    padding: 5
  },
  icon: {
    width: 80,
    height: 80
  },
  icon2: {
    width: 90,
    height: 80
  },
  label: {
    fontWeight: 'bold'
    // color: '#000',
  },

  overlayBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '90%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Cor apagada (aqui, usei um cinza escuro com 50% de transparência)
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center'
  },
  overlayText: {
    fontWeight: 'bold'
  }
})
