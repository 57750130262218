import { Box, Image, Text, useColorMode, useColorModeValue } from 'native-base'
import React, { useState, useRef, useEffect } from 'react'
import { ScrollView } from 'react-native'
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp
} from 'react-native-responsive-screen'
import styles from './styles'

import { useTheme } from '../../../../ThemeProvider.tsx'

import { useNavigation, useRoute } from '@react-navigation/native'
import StorageUtil from '../../../../Utils'
import { TouchableOpacity, Dimensions } from 'react-native'
import agenda from '../../../img/historico.png'
import pacotes from '../../../img/pacotes.png'
import estrela from '../../../img/avaliar.png'
import voltar from '../../../img/voltar.png'
import logout from '../../../img/sair.png'
import ItemMenu from './ItemMenu'
import { CommonActions } from '@react-navigation/native'
import LogoLoginBanner from '../../../img/logo_app.png'
import LogoMaiorBanner from '../../../img/logo_app.png'
import Anonimo from '../../../img/anonimo.png'
import { TitleItem } from '../../../components/Title/Index'

const Menu: React.FC = () => {
  let timer = useRef<any>(0)
  const { getCodEmpresa, primaryColor, configEmpresa } = useTheme()

  const navigation = useNavigation<StackNavigationProp<any>>()

  const { width: SCREEN_WIDTH } = Dimensions.get('window')

  const scrollViewRef = useRef<ScrollView | null>(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [dadosCliente, setDadosCliente] = useState<any>({})

  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa)
  )

  const backgroundColor = getConfigEmpresa.coresPWA.corFundo

  const { colorMode } = useColorMode()

  const route = useRoute()

  const botoes = [
    getConfigEmpresa && getConfigEmpresa.agendaPacotePeloPwa
      ? {
          label: 'Pacotes',
          icon: pacotes,
          onPress: () =>
            navigation.navigate('Pacotes', {
              id_empresa: getCodEmpresa,
              idCliente: route.params.idCliente
            })
        }
      : null,
    {
      label: 'Histórico',
      icon: agenda,
      onPress: () =>
        navigation.navigate('Historico', {
          id_empresa: getCodEmpresa,
          idCliente: route.params.idCliente
        })
    },
    {
      label: 'Avalie',
      icon: estrela,
      disabled: true
    },
    dadosCliente && dadosCliente.admin
      ? {
          label: 'Clientes',
          icon: Anonimo,
          onPress: () =>
            navigation.navigate('Clientes', {
              id_empresa: getCodEmpresa,
              idCliente: route.params.idCliente
            })
        }
      : null,
    {
      label: 'Voltar',
      icon: voltar,
      onPress: () => executaMenu(1)
    },
    {
      label: 'Sair',
      icon: logout,
      onPress: () => executaMenu(2)
    }
  ]

  const images = [LogoLoginBanner, LogoMaiorBanner]

  useEffect(() => {
    clearInterval(timer.current)
    getDadosCliente()

    // setTimeout(() => {
    //     navigation.navigate("Historico", { id_empresa: getCodEmpresa, idCliente: route.params.idCliente })
    // }, 1000);

    startTimer()
    return () => closeTimer()
  }, [])

  async function getDadosCliente() {
    const value = await StorageUtil.getItem('cliente')

    if (value) {
      setDadosCliente(value)
    }
  }

  function startTimer() {
    timer.current = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      )
    }, 5000 * 50)
  }

  function closeTimer() {
    clearInterval(timer.current)
  }

  useEffect(() => {
    scrollViewRef.current?.scrollTo({
      x: currentIndex * SCREEN_WIDTH,
      animated: true
    })
  }, [currentIndex])

  const executaMenu = async (id: number) => {
    if (id == 1) {
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: 'TabNavigator', params: { screen: 'Inicio' } }]
        })
      )
    } else if (id == 2) {
      await StorageUtil.removeItem('cliente')
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: 'TabNavigator', params: { screen: 'Inicio' } }]
        })
      )
    }
  }

  function recuperarNome(nome: string) {
    if (nome) {
      const arrayNome = nome.split(' ')
      return (
        (arrayNome[0] ? arrayNome[0] : '') +
        (arrayNome[1] ? ' ' + arrayNome[1] : '')
      )
    }
    return ''
  }

  const Header = () => {
    return (
      <Box
        style={[
          styles.header,
          {
            backgroundColor: getConfigEmpresa.coresPWA.corFundoHeaderAreaCliente
          }
        ]}
      >
        <Box style={[styles.boxTituloHeader2, {}]}>
          <ItemMenu
            onClick={(id: number) => executaMenu(id)}
            onClose={() => startTimer()}
            onOpen={() => closeTimer()}
          />
        </Box>

        <Box style={[styles.boxTituloHeader, {}]}>
          <Text style={styles.tituloHeader} fontSize='2xl'>
            Olá {recuperarNome(dadosCliente.nome)}{' '}
          </Text>
          {/* >Olá {dadosCliente.nome} </Text> */}
        </Box>
      </Box>
    )
  }

  const Carrocel = () => {
    return (
      <Box style={styles.boxBanner}>
        <ScrollView
          ref={scrollViewRef}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
        >
          {images.map((source: string, index: number) => (
            <Image
              key={index}
              source={{ uri: source }}
              style={{ width: wp('100%'), height: '100%' }}
              resizeMode='cover'
              alt='item'
            />
          ))}
        </ScrollView>
      </Box>
    )
  }

  const Botoes = () => {
    const item = []

    const botoesValidos = botoes.filter((item) => item != null)

    let index = 0
    for (let i = 0; i < botoesValidos.length; i += 2) {
      item.push(
        <Box style={styles.containerItem} key={index}>
          <Box style={styles.buttonContainer}>
            <TouchableOpacity
              style={[
                styles.buttonWrapper,
                {
                  backgroundColor: backgroundColor,
                  borderColor: getConfigEmpresa.coresPWA.corBordaItens
                }
              ]}
              onPress={() => {
                if (!botoesValidos[i].disabled && botoesValidos[i].onPress) {
                  botoesValidos[i].onPress()
                }
              }}
            >
              <Box style={styles.iconContainer}>
                <Image
                  source={{ uri: botoesValidos[i].icon }}
                  alt='Logo'
                  style={styles.icon}
                  resizeMode='cover'
                />
              </Box>
            </TouchableOpacity>

            {botoesValidos[i].disabled && (
              <Box style={styles.overlayBox}>
                <Text
                  style={[
                    styles.overlayText,
                    { color: getConfigEmpresa.coresPWA.corFontePrimaria }
                  ]}
                  fontSize={'xl'}
                >
                  Em Breve
                </Text>
              </Box>
            )}

            <Box style={styles.buttonContainer}>
              <Text
                style={[
                  styles.label,
                  { color: getConfigEmpresa.coresPWA.corFontePrimaria }
                ]}
                fontSize={'xl'}
              >
                {botoesValidos[i].label}
              </Text>
            </Box>
          </Box>

          {botoesValidos[i + 1] ? (
            <Box style={styles.buttonContainer}>
              <TouchableOpacity
                style={[
                  styles.buttonWrapper,
                  {
                    backgroundColor: backgroundColor,
                    borderColor: getConfigEmpresa.coresPWA.corBordaItens
                  }
                ]}
                onPress={() => {
                  if (
                    !botoesValidos[i + 1].disabled &&
                    botoesValidos[i + 1].onPress
                  ) {
                    botoesValidos[i + 1].onPress()
                  }
                }}
              >
                <Box style={styles.iconContainer}>
                  <Image
                    source={{ uri: botoesValidos[i + 1].icon }}
                    alt='Logo'
                    style={styles.icon}
                    resizeMode='cover'
                  />
                </Box>
              </TouchableOpacity>

              {botoesValidos[i + 1].disabled && (
                <Box style={styles.overlayBox}>
                  <Text
                    style={[
                      styles.overlayText,
                      { color: getConfigEmpresa.coresPWA.corFontePrimaria }
                    ]}
                    fontSize={'xl'}
                  >
                    Em Breve
                  </Text>
                </Box>
              )}

              <Box style={styles.buttonContainer}>
                <Text
                  style={[
                    styles.label,
                    {
                      color: getConfigEmpresa.coresPWA.corFontePrimaria
                    }
                  ]}
                  fontSize={'xl'}
                >
                  {botoesValidos[i + 1].label}
                </Text>
              </Box>
            </Box>
          ) : (
            <Box
              style={{
                width: '40%'
              }}
            ></Box>
          )}
        </Box>
      )

      index++
    }

    return item
  }

  return (
    <Box flex={1} backgroundColor={backgroundColor}>
      <Header />
      <Box style={[styles.container, { backgroundColor: backgroundColor }]}>
        <TitleItem title={'Menu'} subtitle='Selecione uma das opções abaixo' />
        <Box style={[styles.boxConteudo, { backgroundColor: backgroundColor }]}>
          <Botoes />
        </Box>
      </Box>
    </Box>
  )
}

export default Menu
