import { StyleSheet } from 'react-native'
import { RFPercentage } from 'react-native-responsive-fontsize'
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from 'react-native-responsive-screen'

export default StyleSheet.create({
  container: {
    width: wp('100%'),
    marginTop: 0.6,
    alignItems: 'center'
    // backgroundColor: '#fff',
  },
  subContainer1: {
    width: '100%',
    // height: hp('35%'),
    alignItems: 'center',
    justifyContent: 'center'
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    opacity: 0.1
  },
  mainText: {
    textAlign: 'center',
    fontWeight: '900',
    letterSpacing: 1,
    // color: 'black',
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 10,
    borderBottomWidth: 1
  },
  button: {
    width: '98%',
    // height: hp('7%'),
    marginTop: 30,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: 11
  },
  buttonText: {
    color: '#fff'
  },
  buttonTextMobile: {
    color: '#fff'
  },
  styleBotaoModal: {
    marginTop: 60
  },
  subContainer2: {
    width: '100%',
    marginTop: 5,
    justifyContent: 'center'
  },
  locationTitleContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
    justifyContent: 'center'
  },
  locationTitle: {
    // color: '#000',
    textAlign: 'center',
    fontWeight: '900'
  },
  iframeContainer: {
    width: wp('100%'),
    alignItems: 'center'
  },
  iframe: {
    border: 0,
    borderRadius: 10
  }
})
