import React, { useEffect, useState } from 'react'
import { TouchableOpacity } from 'react-native'
import { Box, Flex, Text, useColorMode } from 'native-base'

import { useNavigation, useRoute } from '@react-navigation/native'
import createStyles from './styles'
import Template from '../../template/Index'
import { CommonActions } from '@react-navigation/native'
import { useTheme } from '../../../ThemeProvider'
import { AntDesign } from '@expo/vector-icons'
import { Card } from 'primereact/card'

export default function Comprovante({}) {
  const { primaryColor, isMobile, configEmpresa } = useTheme()

  const [parametros, setParametros] = useState<any>(null)

  const navigation = useNavigation()
  const route = useRoute()

  const { colorMode } = useColorMode()
  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa)
  )

  const styles = createStyles(getConfigEmpresa.coresPWA)

  useEffect(() => {
    const parametros_recebidos = route.params as any

    setParametros(parametros_recebidos)
  }, [])

  function renderLinhaFinal() {
    return (
      <Box width={'100%'}>
        <Text style={styles.heading}>Políticas de Cancelamento</Text>

        <Box style={styles.container}>
          <Text style={styles.dateLabelFinal}>
            Se precisar, você tem a opção de cancelar ou alterar seu agendamento
            até 1 hora antes do horário estipulado. Para isso, acesse sua conta
            e siga as instruções disponíveis.
          </Text>
        </Box>
      </Box>
    )
  }
  function renderLinha(
    titulo_principal: String,
    subtitulo: String,
    valor: String,
    isValor: Boolean = false,
    top_menor: Boolean = false
  ) {
    return (
      <Box width={'100%'}>
        <Text
          style={[
            styles.heading,
            {
              marginTop: top_menor ? 5 : 20,
              borderBottomColor:
                getConfigEmpresa.coresPWA.corBordaLinhasConfirmacao
            }
          ]}
          fontSize='lg'
        >
          {titulo_principal}
        </Text>

        <Box style={styles.container}>
          {isValor ? null : (
            <Text style={styles.dateLabel} fontSize='md'>
              {subtitulo}
            </Text>
          )}
          <Text style={styles.dateValue} fontSize='md'>
            {valor}
          </Text>
        </Box>
      </Box>
    )
  }

  function finalizar() {
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: 'TabNavigator', params: { screen: 'Inicio' } }]
      })
    )
  }

  return (
    <Template
      titulo={'Comprovante'}
      escondeBotoesRodape={true}
      onBotaoProximoClick={() => {}}
    >
      <Card
        style={{
          margin: 2,
          width: '100%',
          backgroundColor: getConfigEmpresa.coresPWA.corFundo,
          borderColor:
            getConfigEmpresa.coresPWA.corBordaLinhasConfirmacao || '#000',
          border: 'solid'
        }}
      >
        <Flex
          direction={'row'}
          justifyContent={'center'}
          width={'100%'}
          alignItems={'center'}
        >
          {isMobile && (
            <AntDesign
              name='checkcircle'
              size={65}
              color={primaryColor ?? 'defaultColor'}
              style={{ marginRight: 10 }}
            />
          )}
          {/* {isMobile && <Box style={styles.imageContainer}>
                    <Box style={[styles.innerBox, { backgroundColor: primaryColor ?? 'defaultColor' }]}>
                        <Text style={styles.checkmark} fontSize="6xl">✔</Text>
                    </Box>
                </Box>} */}

          <Box style={[styles.textContainer, !isMobile && { width: '100%' }]}>
            <Text style={styles.confirmedText} fontSize='md'>
              AGENDAMENTO CONFIRMADO!
            </Text>
            <Text style={styles.codeText} fontSize='sm'>
              COD DO SEU AGENDAMENTO #{parametros?.id_agendamento}
            </Text>
          </Box>
        </Flex>
      </Card>

      <Card
        style={{
          margin: 2,
          marginTop: 10,
          width: '100%',
          backgroundColor: getConfigEmpresa.coresPWA.corFundo,
          borderColor:
            getConfigEmpresa.coresPWA.corBordaLinhasConfirmacao || '#000',
          border: 'solid'
        }}
      >
        {renderLinha(
          'Detalhes do Agendamento',
          'DATA E HORA',
          parametros?.data_hora_selecionada,
          false,
          true
        )}
        {renderLinha(
          'Pagamento',
          'VALOR TOTAL',
          'R$ ' + parametros?.total,
          false,
          true
        )}
      </Card>
      {/* {renderLinhaFinal()} */}

      <Box style={styles.containerFinal}>
        <TouchableOpacity
          style={[
            styles.styleButtonFinal,
            { backgroundColor: primaryColor ?? 'defaultColor' }
          ]}
          onPress={() => {
            finalizar()
          }}
        >
          <Text fontSize='lg' style={styles.buttonText}>
            Voltar para Início
          </Text>
        </TouchableOpacity>
      </Box>
    </Template>
  )
}
