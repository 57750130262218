import React, { useEffect, useRef, useState } from 'react'
import {} from 'react-native'
import { Box, Text } from 'native-base'
import { RFPercentage } from 'react-native-responsive-fontsize'

import { AntDesign } from '@expo/vector-icons'
import { useNavigation, useRoute } from '@react-navigation/native'
import Template from '../../../template/Index'
import createStyles from './styles'
import Calendario from '../../../components/Calendario'
import Api from '../../../../Api/Api'
import SkeletonData from '../../../components/Skeleton/Data'
import { useTheme } from '../../../../ThemeProvider'

export default function Data({}) {
  const navigation = useNavigation()
  const route = useRoute()

  const [dataSelecionada, setDataSelecionada] = useState<Date>(new Date())
  const [mensagemRodape, setMensagemRodape] = useState<String>('')
  const [diasAtendimento, setDiasAtendimento] = useState<any[]>([]) // [1,2,3,4,5,6,7
  const [buscando, setBuscando] = useState<Boolean>(true)
  const refModal = useRef<any>(null)

  const {
    configEmpresa,
    getCodEmpresa,
    mesAtual,
    getLimiteAgendamentoFuturo,
    dataLimiteAgendamentoFuturo
  } = useTheme()

  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa as string)
  )

  const styles = createStyles(getConfigEmpresa.coresPWA)

  useEffect(() => {
    getDiasAtendimento()
  }, [])

  async function getDiasAtendimento() {
    const dias_atendimento = await Api.dias_atendimento(getCodEmpresa)

    if (!dias_atendimento.status) {
      // setMensagemRodape(dias_atendimento.mensagem);
      return
    }

    // pega o array e subtrai 1 de cada um dos dias
    const dias_ = dias_atendimento.data.dias.map((dia) => {
      return dia - 1
    })

    setDiasAtendimento(dias_)

    const recupera_dia_semana = dataSelecionada.getDay()

    if (!dias_.includes(recupera_dia_semana)) {
      setMensagemRodape('Desculpa, Não atendemos neste dia da semana!')
    } else {
      setMensagemRodape(
        'Data Selecionada: ' + retornaDataFormatada(dataSelecionada)
      )
    }

    setBuscando(false)
  }

  const retornaTipoMensagem = () => {
    return mensagemRodape.indexOf('Data Selecionada') > -1 ? 1 : 2 //1 atende , 2 não atende
  }

  const retornaNomeDiaSemana = (dia: number) => {
    switch (dia) {
      case 0:
        return 'Domingo'
      case 1:
        return 'Segunda-Feira'
      case 2:
        return 'Terça-Feira'
      case 3:
        return 'Quarta-Feira'
      case 4:
        return 'Quinta-Feira'
      case 5:
        return 'Sexta-Feira'
      case 6:
        return 'Sábado'
    }
  }

  const retornaDataFormatada = (data: Date) => {
    const dia = data.getDate() < 10 ? '0' + data.getDate() : data.getDate()
    const mes =
      data.getMonth() + 1 < 10
        ? '0' + (data.getMonth() + 1)
        : data.getMonth() + 1
    const ano = data.getFullYear()

    return dia + '/' + mes + '/' + ano
  }

  return (
    <Template
      titulo={'Selecione o Dia'}
      messageAlerta='Para agendar um horário, selecione a data desejada'
      disabled_button_proximo={retornaTipoMensagem() == 2}
      onBotaoProximoClick={() => {
        let existeBrindeValidar = false

        if (route.params && route.params.servicos_selecionados) {
          const existeBrinde = route.params.servicos_selecionados.find(
            (item: any) => item.brindeAniversario == true
          )
          const mesSelecionado = dataSelecionada.getMonth() + 1

          if (existeBrinde && mesSelecionado != mesAtual) {
            refModal.current.abrirModal(
              'Desculpa, o brinde de aniversário só é válido para o mês do seu aniversário!'
            )
            return
          }

          if (existeBrinde) {
            existeBrindeValidar = true
          }
        }

        const mesSelecionado = dataSelecionada.getMonth() + 1

        const dataAtualFormatada =
          dataSelecionada.getFullYear() +
          '-' +
          (mesSelecionado < 10 ? 0 + '' + mesSelecionado : mesSelecionado) +
          '-' +
          (dataSelecionada.getDate() < 10
            ? 0 + '' + dataSelecionada.getDate()
            : dataSelecionada.getDate())

        //vamos comparar a data selecionada com a data atual

        if (
          !existeBrindeValidar &&
          dataLimiteAgendamentoFuturo &&
          dataAtualFormatada > dataLimiteAgendamentoFuturo
        ) {
          refModal.current.abrirModal(
            'Desculpa, Só é permitido agendar até ' +
              getLimiteAgendamentoFuturo +
              ' dias a frente!'
          )
          return
        }

        if (retornaTipoMensagem() == 2) {
          return
        }

        if (
          getConfigEmpresa.validaDiasValidadePacote &&
          route.params.pacoteSelecionado
        ) {
          for (const i of route.params.pacoteSelecionado) {
            const diaNumero = dataSelecionada.getDay()

            if (i.diasValidade.indexOf(diaNumero.toString()) == -1) {
              refModal.current.abrirModal(
                'Desculpa, o pacote selecionado não pode ser agendado para o dia de ' +
                  retornaNomeDiaSemana(diaNumero) +
                  '!'
              )
              return
            }
          }
        }

        navigation.navigate('Horarios', {
          ...route.params,
          data_selecionada: dataSelecionada
        })
      }}
      ref={refModal}
    >
      {buscando ? (
        <SkeletonData />
      ) : (
        <Box style={styles.mainContainer}>
          <Calendario
            dataSelecionada={dataSelecionada}
            diasAtendimento={diasAtendimento}
            onChange={(data, valida_dia_menor_atual) => {
              const recupera_dia_semana = data.getDay()

              if (valida_dia_menor_atual) {
                setMensagemRodape(
                  'Descupa, Não é permitido agendar em datas anteriores a data atual!'
                )
              } else if (!diasAtendimento.includes(recupera_dia_semana)) {
                setMensagemRodape(
                  'Desculpa, Não atendemos neste dia da semana!'
                )
              } else {
                setMensagemRodape(
                  'Data Selecionada: ' + retornaDataFormatada(data)
                )
              }

              setDataSelecionada(data)
            }}
          />

          {mensagemRodape != '' && (
            <Box style={styles.boxTexto}>
              <AntDesign
                name={
                  retornaTipoMensagem() == 1 ? 'checkcircle' : 'closecircle'
                }
                size={RFPercentage(3)}
                color={retornaTipoMensagem() == 1 ? 'green' : 'red'}
              />
              <Text style={styles.textoDisp}>{mensagemRodape}</Text>
            </Box>
          )}
        </Box>
      )}
    </Template>
  )
}
