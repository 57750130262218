import React, { useState, useEffect, useRef } from 'react'
import { View } from 'react-native'
import { ScrollView, useColorMode } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import Api from '../../../Api/Api'
import { useTheme } from '../../../ThemeProvider'
import StorageUtil from '../../../Utils'
import Loader from '../../../components/Loader/index'
import Layout1 from './Layout1/Index'
import ModalConfirmacao from '../../../components/ModalConfirmacao'
import styles from './styles'
import Layout2 from './Layout2/Index'

const Inicio: React.FC = () => {
  const {
    primaryColor,
    isMobile,
    fontSizeMobile,
    fontSizeDesktop,
    setPrimaryColor,
    setCodEmpresa,
    setIntervaloAgendamento,
    setBrindeAniversarianteMes,
    setLimiteAgendamentoFuturo,
    setDataLimiteAgendamentoFuturo,
    setConfigEmpresa,
    setMesAtual
  } = useTheme()

  const navigation = useNavigation<StackNavigationProp<any>>()
  const refModal = useRef<any>(null)
  const refModalConfirmaLogin = useRef<any>(null)
  const images = [
    'https://i.ibb.co/Ms5yNC2/foto1.jpg',
    'https://i.ibb.co/wg7YtzR/foto3.jpg',
    'https://i.ibb.co/LkDWFRS/foto2.jpg'
  ]

  const itens_menu = [
    { id: 1, nome_menu: 'Início' },
    { id: 2, nome_menu: 'Serviços' },
    { id: 3, nome_menu: 'Produtos' },
    { id: 4, nome_menu: 'Contato' }
    // { id: 5, nome_menu: "sobre" },
  ]

  const [dados_empresa, setDados_empresa] = useState<any>([])
  const [textoModal, setTextoModal] = useState<string>('')
  const [empresaNaoEncontrada, setEmpresaNaoEncontrada] =
    useState<boolean>(false)

  const [exibeBotaoAgendarPacote, setExibeBotaoAgendarPacote] =
    useState<boolean>(false)
  const [exibeLoader, setExibeLoader] = useState<boolean>(true)
  const [aindaCarregando, setAindaCarregando] = useState<boolean>(true)
  //  "#1976d2"

  const { colorMode, setColorMode } = useColorMode()

  useEffect(() => {
    getDadosEmpresa()

    // setTimeout(() => {
    //     navigation.navigate('InicioStack', {
    //         screen: 'Menu',
    //         params: {
    //             idCliente: 1
    //         }
    //     });
    // }, 1000);

    // setTimeout(() => {
    //     navigation.navigate('TabNavigator', {
    //         screen: 'Login',
    //         params: {
    //             idCliente: 1
    //         }
    //     });
    // }, 1000);

    let aberto = false

    const timer = setTimeout(() => {
      clearTimeout(timer)

      if (!aberto) {
        aberto = true
        validaLogin()
      }
    }, 500)

    const timerLoader = setTimeout(() => {
      clearTimeout(timerLoader)
      setExibeLoader(false)
    }, 2000)

    return () => {
      clearTimeout(timer)
      clearTimeout(timerLoader)
    }
  }, [])

  // foi colocado dentro desse useEffect porque quando a config utilizaPwaSimples
  // estava ativa, não estava esperando carregar para enviar para a tela de serviços
  useEffect(() => {
    if (
      dados_empresa &&
      (dados_empresa.utilizaPwaSimples || dados_empresa.utilizaLayoutV2) &&
      !aindaCarregando &&
      !exibeLoader
    ) {
      if (!dados_empresa.utilizaLayoutV2) {
        setTimeout(async () => {
          await StorageUtil.removeItem('cliente')
        }, 500)
      }

      navigation.navigate('InicioStack', {
        screen: 'Servicos',
        params: {
          logo: dados_empresa.logo
        }
      })
    }
  }, [dados_empresa, aindaCarregando, exibeLoader])

  async function getDadosEmpresa() {
    console.log('buscando dados da empresa')
    const url = window.location.href
    const ignoreValidacao = url.indexOf('ignoreValidacao=true') > -1

    const subdomain = url.split('/')[2].split('.')[0]

    console.log(subdomain)

    const getDadosEmpresa = await Api.dadosEmpresa(subdomain)

    if (!getDadosEmpresa.status) {
      setAindaCarregando(false)
      setEmpresaNaoEncontrada(true)
      setTimeout(() => {
        setTextoModal(
          getDadosEmpresa.error_details
            ? getDadosEmpresa.error_details.message
            : 'Erro ao buscar dados da empresa '
        )
        if (refModal.current) {
          refModal.current.abrirModal()
        }
      }, 3000)

      return
    }

    if (getDadosEmpresa.data.intervaloAgendamento == 0) {
      setTextoModal('#001 - Não foi definido intervalo de agendamento')
      refModal.current.abrirModal()
      return
    }

    //colocar no front exibeTempoServicoPWA

    setBrindeAniversarianteMes(getDadosEmpresa.data.brindeDeAniversario)
    setDados_empresa(getDadosEmpresa.data)
    setCodEmpresa(getDadosEmpresa.data.id)
    setIntervaloAgendamento(getDadosEmpresa.data.intervaloAgendamento)
    setMesAtual(getDadosEmpresa.data.mesAtual)
    setLimiteAgendamentoFuturo(getDadosEmpresa.data.limiteAgendamentoFuturo)
    setDataLimiteAgendamentoFuturo(
      getDadosEmpresa.data.dataLimiteAgendamentoFuturo
    )

    const urlParams = new URLSearchParams(window.location.search)
    let parametrosTeste = null
    const encodedJsonString = urlParams.get('data')
    if (encodedJsonString) {
      const jsonString = decodeURIComponent(encodedJsonString)
      const coresTeste = JSON.parse(jsonString)
      if (coresTeste && coresTeste.length > 0) {
        parametrosTeste = coresTeste.reduce((acc: any, item: any) => {
          const key = Object.keys(item)[0]
          acc[key] = item[key]
          return acc
        }, {})
      }
    }

    if (getDadosEmpresa.data.corPrincipalEmpresa) {
      setPrimaryColor(
        parametrosTeste
          ? parametrosTeste.corPrincipalEmpresa
          : getDadosEmpresa.data.corPrincipalEmpresa
      )
    }

    setConfigEmpresa(
      JSON.stringify({
        permiteAgendarSemCadastro:
          getDadosEmpresa.data.permiteAgendarSemCadastro,
        utilizaPwaSimples: getDadosEmpresa.data.utilizaPwaSimples,
        exibeTempoServicoPWA: parametrosTeste
          ? parametrosTeste.exibeTempoServicoPWA
          : getDadosEmpresa.data.exibeTempoServicoPWA,
        exibeMenuSuperiorPWA: parametrosTeste
          ? parametrosTeste.exibeMenuSuperiorPWA
          : getDadosEmpresa.data.exibeMenuSuperiorPWA,
        ocultaBotoesRodapeServicos:
          getDadosEmpresa.data.ocultaBotoesRodapeServicos,
        naoSolicitarDadosCompletosPWA:
          getDadosEmpresa.data.naoSolicitarDadosCompletosPWA,
        validaDiasValidadePacote: getDadosEmpresa.data.validaDiasValidadePacote,
        utilizaTemaEscuro: getDadosEmpresa.data.utilizaTemaEscuro,
        // intervaloComTempoServico: getDadosEmpresa.data.intervaloComTempoServico,
        utilizaLayoutV2: getDadosEmpresa.data.utilizaLayoutV2,
        ocultarHorariosIndisponiveis:
          getDadosEmpresa.data.ocultarHorariosIndisponiveis,
        removerValidacaoTelefone: getDadosEmpresa.data.removerValidacaoTelefone,
        agendaPacotePeloPwa: getDadosEmpresa.data.agendaPacotePeloPwa,
        versaoPWA: getDadosEmpresa.data.utilizaLayoutV2 ? 2 : 1,
        coresPWA: parametrosTeste
          ? parametrosTeste
          : getDadosEmpresa.data.coresPWA
      })
    )
    setExibeBotaoAgendarPacote(getDadosEmpresa.data.botaoInicioAgPacote)

    if (getDadosEmpresa.data.utilizaTemaEscuro) {
      setColorMode('dark')
    }

    if (!isMobile && !ignoreValidacao) {
      // setTextoModal('Para uma melhor experiência, utilize o celular!')
      // refModal.current.abrirModal()
      window.location.href =
        'http://web.agendasimplificada.com.br?subDomainWeb=' + subdomain

      return
    }

    setAindaCarregando(false)
  }

  async function validaLogin() {
    const urlForceLogin = window.location.href.indexOf('forceLogin=true') > -1
    if (urlForceLogin) {
      const value = await StorageUtil.getItem('cliente')
      if (!value || value.id <= 0) {
        //abre o modal avisando que precisa logar
        setTextoModal('Você precisa estar logado para continuar')
        refModalConfirmaLogin.current.abrirModal()
      }
    }
  }

  function renderViewLoader() {
    return (
      <View style={styles.loader}>
        <Loader />
      </View>
    )
  }

  function renderViewConteudo() {
    return (
      // o modelo do pwa escolhido atualmente da para fazer dentro do template na tela de serviços,
      // quando escolher outro modelo mais para frente, ai utiliza dessa forma aqui, pois ela separa totalmente as telas.
      // dados_empresa.utilizaLayoutV2 ?
      //     <Layout2
      //      dados_empresa={dados_empresa}
      //     />
      //     :
      //     <Layout1
      //         dados_empresa={dados_empresa}
      //         empresaNaoEncontrada={empresaNaoEncontrada}
      //         exibeBotaoAgendarPacote={exibeBotaoAgendarPacote}
      //         isMobile={isMobile}
      //         colorMode={colorMode}
      //         primaryColor={primaryColor}
      //     />
      <Layout1
        dados_empresa={dados_empresa}
        empresaNaoEncontrada={empresaNaoEncontrada}
        exibeBotaoAgendarPacote={exibeBotaoAgendarPacote}
        isMobile={isMobile}
        colorMode={colorMode}
        primaryColor={primaryColor}
      />
    )
  }

  return (
    <ScrollView>
      {exibeLoader || aindaCarregando
        ? renderViewLoader()
        : renderViewConteudo()}

      <ModalConfirmacao
        titulo={'Atenção'}
        ref={refModalConfirmaLogin}
        mensagem={textoModal}
        textoBotaoPositivo={'OK'}
        onConfirmPositivo={() => {
          if (refModalConfirmaLogin.current) {
            refModalConfirmaLogin.current.fecharModal()
            navigation.navigate('TabNavigator', {
              screen: 'Login',
              params: {
                retornaLogin: true
              }
            })
          }
        }}
      />
    </ScrollView>
  )
}

export default Inicio

{
  /* <Avaliacoes 
avaliacoes={[
    {
        url: 'https://image.freepik.com/fotos-gratis/retrato-de-homem-branco-isolado_53876-40306.jpg',
        nota: 5,
        nome_cliente: "Adriel Dias",
        comentario: "Excelente atendimento"
    },
    
]}
/> */
}
