import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, Image, Text, useColorMode } from 'native-base'
import { useNavigation, useRoute } from '@react-navigation/native'
import Api from '../../../../Api/Api'
import createStyle from './styles'
import Template from '../../../template/Index.tsx'
import { useTheme } from '../../../../ThemeProvider.tsx'
import ModalConfirmacao from '../../../../components/ModalConfirmacao'
import { Card } from 'primereact/card'

export default function Confirmacao({}) {
  const navigation = useNavigation()
  const route = useRoute()

  const [data_selecionada, setDataSelecionada] = useState<Date>(new Date())
  const [servicos_selecionado, setServicos_selecionado] = useState<any>([])
  const [pacotesSelecionado, setPacotesSelecionado] = useState<any>([])
  const [total, setTotal] = useState<Number>(0)
  const [nome_cliente, setNome_cliente] = useState<String>('')
  const [telefone_cliente, setTelefone_cliente] = useState<String>('')
  const [
    mensagemConfirmaUtilizacaoPacote,
    setMensagemConfirmaUtilizacaoPacote
  ] = useState<string>('')
  const [pacotes, setPacotes] = useState<any[]>([])
  const [pacoteSelecionadoValidacao, setPacoteSelecionadoValidacao] = useState<
    any[]
  >([])
  const [textoModalInformacao, setTextoModalInformacao] = useState<string>('')
  const [clienteFinal, setClienteFinal] = useState<boolean>(false)

  const { getCodEmpresa, isMobile, configEmpresa } = useTheme()
  const { colorMode } = useColorMode()

  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa)
  )
  const refModal = useRef<any>(null)
  const refModalInformacao = useRef<any>(null)
  const confirmationRef = useRef<any>(null)

  const style = createStyle(getConfigEmpresa.coresPWA)

  const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ]

  const dias_semana = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sabado'
  ]

  useEffect(() => {
    setDataSelecionada(route.params.data_selecionada)
    setServicos_selecionado(
      route.params.servicos_selecionados
        ? route.params.servicos_selecionados
        : []
    )
    setPacotesSelecionado(
      route.params.pacoteSelecionado ? route.params.pacoteSelecionado : []
    )
    setNome_cliente(route.params.cliente.nome)
    setTelefone_cliente(route.params.cliente.telefone)
    setClienteFinal(route.params.cliente.clienteFinal)

    let total = 0

    if (route.params.servicos_selecionados) {
      for (const servico of route.params.servicos_selecionados) {
        total += parseFloat(servico.valor)
      }
    }

    setTotal(total)
    getPacotesCliente()
  }, [])

  async function validaPacotesDentroPrazo(pacoteVerificar: any[]) {
    const pacotesValidos = pacoteVerificar.filter((item: any) => {
      const data = new Date(item.dataValidade)
      if (data_selecionada <= data) {
        return item
      }
    })

    return pacotesValidos
  }

  async function validaItensPacotes(pacotesValidos: any[]) {
    for (const pacote of pacotesValidos) {
      const itensValidos = pacote.pacoteVendaItens.filter((item: any) => {
        if (item.produto) {
          return
        }

        if (item.ilimitado) {
          return item
        }

        if (item.quantidadeUtilizada < item.quantidade) {
          return item
        }
      })

      delete pacote.pacoteVendaItens

      pacote.pacoteVendaItens = itensValidos
    }

    return pacotesValidos
  }

  async function confirmarNaoUtilizarPacote() {
    await confirmarAgendamento(pacotesSelecionado, servicos_selecionado)

    if (confirmationRef.current) {
      confirmationRef.current.fecharModal()
    }
  }

  async function retornaItensSelecionado(pacotes: any[]) {
    const itensSelecionado = []

    for (const pacote of pacotes) {
      for (const item of pacote.pacoteVendaItens) {
        if (item.selecionado) {
          itensSelecionado.push(item)
        }
      }
    }

    return itensSelecionado
  }

  async function confirmarUtilizacaoPacote() {
    const pacotesSelect = Object.assign([], pacotesSelecionado)
    const servicosEnviar = Object.assign([], servicos_selecionado)
    pacotesSelect.push(...pacoteSelecionadoValidacao)

    for (const item of pacotesSelect) {
      if (item.funcionario.id != route.params.profissional_selecionado.id) {
        setTextoModalInformacao(
          `Para utilizar esse pacote é necessário selecionar o profissional: ${item.funcionario.nome.toUpperCase()}, volte e selecione o profissional correto`
        )
        refModalInformacao.current.abrirModal()

        return
      }
    }

    const itensSelecionado = await retornaItensSelecionado(pacotesSelect)

    if (itensSelecionado.length > 0) {
      for (const item of itensSelecionado) {
        const index = servicosEnviar.findIndex((item_servico: any) => {
          return item_servico.id == item.servico.id
        })

        if (index > -1) {
          servicosEnviar.splice(index, 1)
        }
      }
    }

    if (confirmationRef.current) {
      confirmationRef.current.fecharModal()
    }
    confirmarAgendamento(pacotesSelect, servicosEnviar)
  }

  async function agendar() {
    if (pacotes.length > 0) {
      const pctValidar = Object.assign([], pacotes)

      const pacotesValidos = await validaPacotesDentroPrazo(pctValidar)
      const pacotesComItensValidos = await validaItensPacotes(pacotesValidos)

      const pacoteEncontrado = []

      for (const item_servico of servicos_selecionado) {
        let encontrado = false

        // passo verificando se esse serviço existe dentro de algum pacote
        for (const pacotesValidos of pacotesComItensValidos) {
          for (const itensPacote of pacotesValidos.pacoteVendaItens) {
            if (itensPacote.servico.id == item_servico.id) {
              const identificaPacoteOriginal = pacotes.findIndex((item) => {
                return item.id == pacotesValidos.id
              })

              // marca no item do pacote original como selecionado

              const pacoteSelec = pacotes[identificaPacoteOriginal]

              for (const encontraItem of pacoteSelec.pacoteVendaItens) {
                if (encontraItem.id == itensPacote.id) {
                  encontraItem.selecionado = true
                  encontrado = true
                  break
                }
              }

              pacoteEncontrado.push(pacoteSelec)
              encontrado = true
              break
            }
          }

          if (encontrado) {
            break
          }
        }
      }

      if (pacoteEncontrado.length > 0) {
        setPacoteSelecionadoValidacao(pacoteEncontrado)

        let nome_produtos = ''

        for (const pacote of pacoteEncontrado) {
          for (const item of pacote.pacoteVendaItens) {
            if (item.selecionado) {
              nome_produtos += item.servico.nome + ', '
            }
          }
        }

        setMensagemConfirmaUtilizacaoPacote(
          'Você possui pacote para os serviços: ' +
            nome_produtos +
            ' deseja utilizar ?'
        )
        if (confirmationRef.current) {
          confirmationRef.current.abrirModal()
        }

        return
      }
    }

    await confirmarAgendamento(pacotesSelecionado, servicos_selecionado)

    return
  }

  async function confirmarAgendamento(
    pacotesUtilizado: any[],
    servicos_selecionado_: any[]
  ) {
    await refModal.current.abrirLoader()

    const agendar = await Api.agendar(
      data_selecionada,
      route.params.horarios_selecionado,
      route.params.cliente,
      servicos_selecionado_,
      route.params.profissional_selecionado,
      getCodEmpresa,
      pacotesUtilizado
    )

    if (!agendar.status) {
      await refModal.current.fecharLoader()
      await refModal.current.abrirModal(
        agendar.error_details
          ? agendar.error_details.message
          : 'Erro ao agendar'
      )
      return
    }

    const cod_agendamento = agendar.data.agendendamento.id

    const formata_data =
      data_selecionada.getDate() +
      '/' +
      (data_selecionada.getMonth() + 1) +
      '/' +
      data_selecionada.getFullYear() +
      ' ás ' +
      route.params.horarios_selecionado.horario

    await refModal.current.fecharLoader()

    navigation.navigate('Comprovante', {
      data_hora_selecionada: formata_data,
      total: total.toFixed(2).replace('.', ','),
      id_agendamento: cod_agendamento
    })
  }

  async function getPacotesCliente() {
    await refModal.current.abrirLoader()

    const servicos = await Api.pacotesCliente(
      getCodEmpresa,
      route.params.cliente.id
    )

    if (!servicos.status) {
      refModal.current.abrirModal('Erro ao buscar Pacotes')
      await refModal.current.fecharLoader()
      return
    }

    setPacotes(servicos.data)

    await refModal.current.fecharLoader()
  }

  function formataTelefone(text: String) {
    text = text.replace(/\D/g, '')

    let newCleaned = text.replace(/\D/g, '').substring(0, 11) // remove non-digit and limit
    if (newCleaned !== '') {
      let formatted = newCleaned
      if (newCleaned.length >= 2) {
        formatted = `(${newCleaned.substring(0, 2)}) ${newCleaned.substring(2)}`
      }
      if (newCleaned.length >= 7) {
        formatted = `(${newCleaned.substring(0, 2)}) ${newCleaned.substring(
          2,
          7
        )}-${newCleaned.substring(7)}`
      }

      return formatted
    }
  }

  function linhaDadosPessoais(titulo: String, valor: String) {
    return (
      <Flex style={style.flexContainerDadosPessoais}>
        <Text
          style={[
            style.textLinhaPessoa,
            {
              marginRight: 10,
              color: getConfigEmpresa.coresPWA.corFontePrimaria
            }
          ]}
          fontSize='md'
        >
          {titulo}
        </Text>

        <Text
          style={[
            style.textLinhaPessoa,
            {
              color: getConfigEmpresa.coresPWA.corFontePrimaria
            }
          ]}
          fontSize='md'
        >
          {valor}
        </Text>
      </Flex>
    )
  }

  function informacoesPagamento() {
    return (
      <Box
        style={[
          style.servicosContainer,
          {
            borderBottomColor:
              getConfigEmpresa.coresPWA.corBordaLinhasConfirmacao || '#000'
          }
        ]}
      >
        <Flex style={style.footerFlexContainer}>
          <Text style={style.footerText} fontSize='lg'>
            Tem Cupom ?
          </Text>
          <Text style={style.footerText} fontSize='lg'>
            Digitar
          </Text>
        </Flex>

        <Flex style={style.footerFlexContainer}>
          <Text style={style.footerBoldText} fontSize='lg'>
            Total
          </Text>
          <Text style={style.footerBoldText} fontSize='lg'>
            {'R$ ' + total.toFixed(2).replace('.', ',')}
          </Text>
        </Flex>

        <Flex style={style.footerFlexContainer}>
          <Text style={style.footerBoldText} fontSize='lg'>
            Subtotal
          </Text>
          <Text style={style.footerBoldText} fontSize='lg'>
            {'R$ ' + total.toFixed(2).replace('.', ',')}
          </Text>
        </Flex>
      </Box>
    )
  }

  function linhaServicos() {
    return (
      <Box
        style={[
          style.servicosContainer,
          {
            borderBottomColor:
              getConfigEmpresa.coresPWA.corBordaLinhasConfirmacao || '#000'
          }
        ]}
      >
        <Text style={style.servicosLabel} fontSize='lg'>
          Serviços:
        </Text>
        {servicos_selecionado.map((item: any, index: number) => (
          <Box key={index} style={style.itemContainer}>
            <Flex style={style.flexContainer}>
              <Text style={style.itemNome} fontSize='lg'>
                {item.nome}
              </Text>
              <Text
                style={{
                  ...style.itemValor,
                  width: '100%',
                  textAlign: 'right'
                }}
                fontSize='lg'
              >
                {' R$ ' + item.valor}
              </Text>
            </Flex>
          </Box>
        ))}
      </Box>
    )
  }

  function renderSelecionados(item: any, index: number) {
    // recupera todos os item.pacoteVendaItens que tem selecionado true

    const servicos = item.pacoteVendaItens.filter(
      (item: any) => item.selecionado == true
    )

    return servicos.map((item: any, index: number) => (
      <Box key={index} style={style.itemContainer}>
        <Flex style={style.flexContainer}>
          <Text style={style.itemNome} fontSize='md'>
            {(item.produto ? item.produto.descricao : item.servico.nome) +
              (item.produto ? ' - Produto' : ' - Serviço')}
          </Text>
        </Flex>
      </Box>
    ))
  }

  function linhaPacotes() {
    return (
      <Box
        style={[
          style.servicosContainer,
          {
            borderBottomColor:
              getConfigEmpresa.coresPWA.corBordaLinhasConfirmacao || '#000'
          }
        ]}
      >
        <Text style={style.servicosLabel} fontSize='lg'>
          Pacote:
        </Text>
        {pacotesSelecionado.map((item: any, index: number) =>
          renderSelecionados(item, index)
        )}
      </Box>
    )
  }

  function linhaProfissional() {
    return (
      <Box
        style={[
          style.profissionalContainer,
          {
            borderBottomColor:
              getConfigEmpresa.coresPWA.corBordaLinhasConfirmacao || '#000'
          }
        ]}
      >
        <Text style={style.profissionalLabel} fontSize='md'>
          Profissional:
        </Text>

        <Box style={style.innerBox}>
          <Flex style={style.flexImageContainer}>
            {isMobile && (
              <Image
                source={
                  route.params &&
                  route.params.profissional_selecionado.foto == null
                    ? require('../../../img/avatar-pessoa.jpg')
                    : {
                        uri:
                          route.params &&
                          route.params.profissional_selecionado.foto?.url
                      }
                }
                alt='Avatar'
                resizeMode='cover'
                style={style.professionalImage}
              />
            )}
            <Text
              style={[style.professionalName, !isMobile && { marginLeft: 0 }]}
            >
              {route.params && route.params.profissional_selecionado.nome}
            </Text>
          </Flex>
        </Box>
      </Box>
    )
  }

  function linhaHorarios() {
    return (
      <Flex
        style={[
          style.flexHorarioContainer,
          {
            borderBottomColor:
              getConfigEmpresa.coresPWA.corBordaLinhasConfirmacao || '#000'
          }
        ]}
      >
        <Text style={style.labelHorarioText} fontSize='md'>
          Horário:
        </Text>

        <Text style={style.dataHorarioText} fontSize='md'>
          {route.params && route.params.horarios_selecionado.horario}
        </Text>
      </Flex>
    )
  }

  function linhaData() {
    const data_formatada =
      dias_semana[data_selecionada.getDay()] +
      ' ' +
      data_selecionada.getDate() +
      ' ' +
      meses[data_selecionada.getMonth()] +
      ' de ' +
      data_selecionada.getFullYear()

    return (
      <Flex style={style.flexContainer}>
        <Text style={style.labelText} fontSize='md'>
          Data:
        </Text>

        <Text style={style.dataText} fontSize='md'>
          {data_formatada}
        </Text>
      </Flex>
    )
  }

  return (
    <Template
      titulo={'Confirme seu Agendamento'}
      // disabled_button_proximo={mensagemRodape != ''}
      messageAlerta='Por favor, verifique com cuidado e confirme os dados do agendamento'
      titulo_botao_proximo={'Agendar'}
      onBotaoProximoClick={() => {
        agendar()
      }}
      onBotaoVoltarClick={() => {
        if (route.params && route.params.validarLogin) {
          route.params.validarLogin()
        }

        navigation.goBack()
      }}
      ref={refModal}
    >
      <Box style={style.mainContainer}>
        <Card
          style={{
            margin: 2,
            width: '100%',
            backgroundColor: getConfigEmpresa.coresPWA.corFundo,
            borderColor:
              getConfigEmpresa.coresPWA.corBordaLinhasConfirmacao || '#000',
            border: 'solid'
          }}
        >
          <Text
            style={{
              fontWeight: '600',
              borderBottomWidth: 1,
              borderBottomColor:
                getConfigEmpresa.coresPWA.corBordaLinhasConfirmacao || '#000',
              width: '100%',
              color: getConfigEmpresa.coresPWA.corFontePrimaria
            }}
            fontSize='xl'
          >
            Informações pessoais
          </Text>

          {linhaDadosPessoais('Nome: ', nome_cliente)}
          {!clienteFinal &&
            linhaDadosPessoais('Telefone: ', formataTelefone(telefone_cliente))}
        </Card>
        <Card
          style={{
            margin: 2,
            marginTop: 10,
            width: '100%',
            backgroundColor: getConfigEmpresa.coresPWA.corFundo,
            borderColor:
              getConfigEmpresa.coresPWA.corBordaLinhasConfirmacao || '#000',
            border: 'solid'
          }}
        >
          <Text
            style={{
              fontWeight: '600',
              borderBottomWidth: 1,
              borderBottomColor:
                getConfigEmpresa.coresPWA.corBordaLinhasConfirmacao || '#000',
              width: '100%',
              color: getConfigEmpresa.coresPWA.corFontePrimaria
            }}
            fontSize='xl'
          >
            Informações do agendamento
          </Text>
          {linhaData()}
          {linhaHorarios()}
          {linhaProfissional()}
          {servicos_selecionado.length > 0 && linhaServicos()}
        </Card>
        <Card
          style={{
            margin: 2,
            marginTop: 10,
            width: '100%',
            backgroundColor: getConfigEmpresa.coresPWA.corFundo,
            borderColor:
              getConfigEmpresa.coresPWA.corBordaLinhasConfirmacao || '#000',
            border: 'solid'
          }}
        >
          <Text
            style={{
              fontWeight: '600',
              borderBottomWidth: 1,
              borderBottomColor:
                getConfigEmpresa.coresPWA.corBordaLinhasConfirmacao || '#000',
              width: '100%',
              color: getConfigEmpresa.coresPWA.corFontePrimaria
            }}
            fontSize='xl'
          >
            Informações do pagamento
          </Text>
          {pacotesSelecionado.length > 0 && linhaPacotes()}
          {informacoesPagamento()}
        </Card>
      </Box>

      <ModalConfirmacao
        ref={confirmationRef}
        titulo={'Atenção'}
        mensagem={mensagemConfirmaUtilizacaoPacote}
        textoBotaoNegativo={'Não Utilizar'}
        textoBotaoPositivo={'Utilizar'}
        onConfirmNegativo={() => confirmarNaoUtilizarPacote()}
        onConfirmPositivo={() => confirmarUtilizacaoPacote()}
      />

      <ModalConfirmacao
        titulo={'Atenção'}
        ref={refModalInformacao}
        mensagem={textoModalInformacao}
        textoBotaoPositivo={'OK'}
        onConfirmPositivo={() => {
          if (refModalInformacao.current) {
            refModalInformacao.current.fecharModal()
          }
          if (confirmationRef.current) {
            confirmationRef.current.fecharModal()
          }
        }}
      />
    </Template>
  )
}
