import { Text } from 'native-base'
import { HrItem } from '../HRItem/Index'
import React, { useState } from 'react'
import { useTheme } from '../../../ThemeProvider'

interface TitleItemProps {
  title: string
  fontSize?: string
  subtitle?: string
  hideHR?: boolean
  blackColor?: boolean
}

export const TitleItem = (props: TitleItemProps) => {
  const { configEmpresa } = useTheme()
  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa)
  )
  return (
    <>
      <Text
        fontSize={props.fontSize ?? 'lg'}
        style={{ color: getConfigEmpresa.coresPWA.corFontePrimaria }}
      >
        <strong>{props.title}</strong>
      </Text>
      {props.subtitle && (
        <Text
          fontSize='md'
          style={{ color: getConfigEmpresa.coresPWA.corFontePrimaria }}
        >
          {props.subtitle}
        </Text>
      )}
      {props.hideHR ? null : <HrItem blackColor={props.blackColor} />}
    </>
  )
}
