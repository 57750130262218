import { Divider } from 'native-base'

interface HrItemProps {
  borderWidth?: number
  marginTop?: number
  marginBottom?: number
  blackColor?: boolean
}

export const HrItem = (props: HrItemProps) => {
  return (
    <Divider
      style={{
        borderColor: props.blackColor ? '#ececec' : '#007bff',
        borderWidth: props.borderWidth ?? 1,
        marginTop: props.marginTop ?? 8,
        marginBottom: props.marginBottom ?? 0
      }}
    />
  )
}
