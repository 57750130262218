import React, { useEffect, useRef, useState } from 'react'
import { Text, TouchableOpacity } from 'react-native'
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from 'react-native-responsive-screen'
import { Box, Flex, Image } from 'native-base'
import { RFPercentage } from 'react-native-responsive-fontsize'
import Input from '../../../../components/Input'
import { useNavigation, useRoute } from '@react-navigation/native'
import Api from '../../../../Api/Api'
import Template from '../../../template/Index'
import createStyles from './styles'
import SkeletonItens from '../../../components/Skeleton/Itens'
import { FlatList } from 'react-native'
import { useTheme } from '../../../../ThemeProvider'
import { CommonActions } from '@react-navigation/native'
import StorageUtil from '../../../../Utils'
import Loader from '../../../../components/Loader'

export default function Clientes({}) {
  const navigation = useNavigation()
  const route = useRoute()

  const [clientes, setClientes] = useState<any[]>([])
  const [clientesOriginal, setClientesOriginal] = useState<any[]>([])
  const [filtro, setFiltro] = useState<String>('')
  const [buscando, setBuscando] = useState<Boolean>(true)
  const [loader, setLoader] = useState<Boolean>(false)

  const { getCodEmpresa, configEmpresa } = useTheme()

  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa)
  )

  const styles = createStyles(getConfigEmpresa.coresPWA)

  const refModal = useRef<any>(null)
  const refLoader = useRef<any>(null)

  useEffect(() => {
    getClientes()
  }, [])

  async function getClientes() {
    let profissionais = await Api.clientes(getCodEmpresa)
    console.log('clientes ', profissionais)

    setClientes(profissionais.data)
    setClientesOriginal(profissionais.data)
    setBuscando(false)
  }

  function renderFiltro() {
    return (
      <Input
        colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
        corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
        value={filtro as string}
        type={'text'}
        icon={'search'}
        onChangeText={(text) => {
          setFiltro(text)
          const clientes_copy = [...clientesOriginal]

          const clientes_filtrados = clientes_copy.filter((item) => {
            return item.nome.toUpperCase().includes(text.toUpperCase())
          })

          setClientes(clientes_filtrados)
        }}
        width={'100%'}
        placeholder='Pesquise pelo profissional'
      />
    )
  }

  async function onPressItemSelecionado(item: any) {
    setLoader(true)

    if (item.alterarSenha) {
      setLoader(false)
      refModal.current.abrirModal('Cliente necessita alterar a senha')
      return
    } else if (item.dataNascimento == null) {
      setLoader(false)
      refModal.current.abrirModal(
        'Cliente necessita informar a data de nascimento'
      )
      return
    }

    const dados = {
      id: item.id,
      nome: item.nome,
      telefone: item.telefone,
      alterarSenha: item.alterarSenha,
      dtNascimento: item.dataNascimento,
      admin: false,
      loginAdmin: true
    }

    await StorageUtil.removeItem('cliente')
    await StorageUtil.setItem('cliente', dados)

    setLoader(false)
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: 'Login', params: { forcaLogin: true } }]
      })
    )
  }

  function linhaProfissional(item: any, index: number) {
    return (
      <Flex key={index} style={styles.containerFlex}>
        {/* vai pegar cores */}
        <Text style={styles.styleText}>
          <Text style={styles.fontTexto}> Id: </Text> {item.id}
        </Text>
        <Text style={styles.styleText}>
          <Text style={styles.fontTexto}> Nome: </Text> {item.nome}
        </Text>
        <Text style={styles.styleText}>
          <Text style={styles.fontTexto}> data Nascimento: </Text>{' '}
          {item.dataNascimento}
        </Text>
        <Text style={styles.styleText}>
          <Text style={styles.fontTexto}> Telefone: </Text>
          {item.telefone}
        </Text>
      </Flex>
    )
  }

  const renderItem = ({ item, index }: any) => (
    <TouchableOpacity
      key={index}
      style={[styles.pressableItem]}
      onPress={() => onPressItemSelecionado(item)}
    >
      {buscando ? <SkeletonItens /> : linhaProfissional(item, index)}
    </TouchableOpacity>
  )

  const renderEmptyComponent = () => (
    <Text
      style={{
        textAlign: 'center',
        marginTop: 20,
        fontSize: RFPercentage(2.5)
      }}
    >
      Nenhum registro encontrado.
    </Text>
  )

  return (
    <Template
      titulo={'Selecione o Cliente'}
      onBotaoProximoClick={() => {
        refModal.current.abrirModal('Por favor, selecione um cliente')
      }}
      filtro={renderFiltro()}
      onBotaoVoltarClick={() => {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: 'TabNavigator', params: { screen: 'Inicio' } }]
          })
        )
      }}
      ref={refModal}
    >
      <Box style={styles.mainContainer}>
        <FlatList
          data={clientes}
          renderItem={renderItem}
          ListEmptyComponent={
            buscando ? <SkeletonItens /> : renderEmptyComponent
          }
          keyExtractor={(item, index) => index.toString()}
          contentContainerStyle={{
            width: wp('98%')
          }}
        />
      </Box>

      {loader && <Loader ref={refLoader} />}
    </Template>
  )
}
